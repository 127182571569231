export default {
  sign: {
    up: {
      password: 'Your password is not correct',
    },
    in: {
      password: 'Your password is not correct',
    },
  },
};
