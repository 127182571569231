// Dashboard
import Dashboard from 'screens/dashboard';

// Public
import SignIn from 'screens/public/sign/in';
import AuthCallback from 'screens/public/sign/auth-callback';

export const routesPublic = [
  {
    path: '/signin',
    exact: true,
    component: SignIn,
    isPublic: true,
  },
  {
    path: '/auth/callback',
    exact: false,
    component: AuthCallback,
    isPublic: true,
  },
];

const routes = [
  ...routesPublic,
  {
    path: '/',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    isPublic: false,
  },
];

export default routes;
