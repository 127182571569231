import gql from 'graphql-tag';

// TODO: change query inputs
export const CREATE_CATEGORY = gql`
  mutation createProjectCategory($name: String!, $displayColor: String!) {
    createProjectCategory(name: $name, displayColor: $displayColor) {
      name
      displayColor
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation editProjectCategory(
    $id: ID!
    $name: String!
    $displayColor: String!
  ) {
    editProjectCategory(id: $id, name: $name, displayColor: $displayColor) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteProjectCategory($id: ID!) {
    deleteProjectCategory(id: $id) {
      status
      message
    }
  }
`;

export const ASSIGN_CATEGORIES_PROJECT = gql`
  mutation assignCategoriesToProject($projectId: ID!, $categoryIds: [ID!]!) {
    assignCategoriesToProject(
      projectId: $projectId
      categoryIds: $categoryIds
    ) {
      status
      description
    }
  }
`;

export const REMOVE_CATEGORIES_PROJECT = gql`
  mutation removeCategoriesFromProject(
    $projectId: ID!
    $categoryIds: [ID!]!
  ) {
    removeCategoriesFromProject(
      projectId: $projectId
      categoryIds: $categoryIds
    ) {
      status
      description
    }
  }
`;
