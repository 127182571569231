export const KEYS = {
  avatarLink: 'avatarLink',
  adminCocoonDrawer: 'adminCocoonDrawer',
  adminCocoonProjectColumns: 'adminCocoonProjectColumns',
  adminCocoonQueryFilter: 'adminCocoonQueryFilter',
  adminCocoonUserColumns: 'adminCocoonUserColumns',
};

export const getLocalStorage = key => localStorage.getItem(key);

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const clearAllLocalStorage = () => {
  Object.values(KEYS).forEach(value => localStorage.removeItem(value));
};
