import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { arrayOf, func, object } from 'prop-types';
import { connect } from 'react-redux';

import { openSnackbar } from 'reducers/snackbarReducer';
import { getViewCount, setViewCount } from 'reducers/viewCountReducer';
import { GET_VIEW_COUNTS } from 'apollo/queries/analysis-query';
import { getToggle } from 'reducers/toggleReducer';
import { Alert, CircularCenterLoader } from 'components';

import { capitalize } from '../../../../helpers/url';

import Analysis from './Analysis';

const AnalysisContainer = ({
  handleOpenSnackbar,
  handleSetViewCount,
  viewCount,
  toggle,
}) => {
  const [viewCountData, setViewCountData] = useState([]);

  //viewCount
  const [
    getViewCountsCb,
    { data: dataViewCount, loading: loadingViewCount, error: errorViewCount },
  ] = useLazyQuery(GET_VIEW_COUNTS, {
    onCompleted: () => {
      handleSetViewCount(dataViewCount.viewCount);
    },
  });

  useEffect(() => {
    getViewCountsCb();
  }, []);

  useEffect(() => {
    if (viewCount) {
      const generateData = viewCount.map((element, index) => {
        let splitUrl = element.pageUrl.split('/');
        let type = '';
        let name = '';
        let lastUser = element.users[element.users.length - 1];
        if (splitUrl.length === 2 && splitUrl[1] === '') {
          type = 'Homepage';
        } else if (splitUrl.length > 3) {
          type = capitalize(splitUrl[1]);
          name = capitalize(splitUrl[3].replace(/-/g, ' '), true);
        } else {
          if (splitUrl[1].includes('-'))
            type = capitalize(splitUrl[1].replace(/-/g, ' '), true);
          else type = capitalize(splitUrl[1]);
        }
        return {
          id: index,
          pageUrl: process.env.MAIN_WEB_APP_URL + element.pageUrl,
          count: element.count,
          lastUser: {
            id: lastUser.id,
            count: lastUser.views,
          },
          lastView: new Date(lastUser.timestamp).toLocaleDateString(),
          column: {
            type: type,
            name: name,
          },
        };
      });
      setViewCountData(generateData);
    }
  }, [viewCount]);

  if (loadingViewCount) return <CircularCenterLoader height={380} />;

  if (errorViewCount) {
    return (
      <Alert variant="outlined" severity="error">
        {errorViewCount && errorViewCount.message}
      </Alert>
    );
  }

  return (
    <Analysis
      viewCount={viewCountData}
      refetch={getViewCount}
      handleOpenSnackbar={handleOpenSnackbar}
      loading={loadingViewCount}
    />
  );
};

AnalysisContainer.propTypes = {
  handleOpenSnackbar: func.isRequired,
  handleSetViewCount: func.isRequired,
  viewCount: arrayOf(object),
};

const mapStateToProps = state => ({
  viewCount: getViewCount(state),
  toggle: getToggle(state),
});

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleSetViewCount: viewCount => {
    dispatch(setViewCount(viewCount));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisContainer);
