import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { arrayOf, func, object } from 'prop-types';
import { connect } from 'react-redux';
import _, { get } from 'lodash';

import { cookieUserId } from 'helpers/auth';
import {
  GET_PROJECTS,
  GET_PROJECTS_PAGE_TOTAL,
} from 'apollo/queries/project-query';
import { openSnackbar } from 'reducers/snackbarReducer';
import {
  FEATURE_PROJECT,
  UNFEATURE_PROJECT,
} from 'apollo/mutations/project-mutation';
import {
  ASSIGN_CATEGORIES_PROJECT,
  REMOVE_CATEGORIES_PROJECT,
} from 'apollo/mutations/project-category-mutation';
import { Alert } from 'components';
import {
  ADD_SPONSORS_PROJECT,
  DELETE_SPONSORS_PROJECT,
} from 'apollo/mutations/project-affiliation-mutation';
import { getCategories } from 'reducers/categoryReducer';
import { getUsers } from 'reducers/userReducer';
import { getProjects, setProjects } from 'reducers/projectReducer';

import Projects from './Projects';

const ProjectsContainer = ({
  handleOpenSnackbar,
  projects,
  categories,
  users,
  handleSetProjects,
}) => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState('FIVE_O');
  const [pageTotalCount, setPageTotalCount] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState('DATE_CREATED_ASC');
  const [getProjectsCb, { data, loading, error }] = useLazyQuery(GET_PROJECTS, {
    variables: {
      page,
      limit,
      filter: orderBy,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleSetProjects(data.projects);
    },
  });

  const handleSetPageTotalCount = pageTotal => {
    const pageTotalData = pageTotal.projectsPageTotal;
    if (pageTotalData) {
      setPageTotalCount(pageTotalData.total);
    }
    // setPageTotalCount(count);
  };

  const [
    getProjectsPageTotal,
    { data: pageTotal, loading: loadingTotal, error: errorTotal },
  ] = useLazyQuery(GET_PROJECTS_PAGE_TOTAL, {
    variables: {
      limit,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      // setPageTotalCount(2);
      handleSetPageTotalCount(pageTotal);
    },
  });

  useEffect(() => {
    // check if page and limit are changed
    if (page !== 1 || limit !== 'FIVE_O' || orderBy !== 'DATE_CREATED_ASC') {
      getProjectsCb();
      getProjectsPageTotal();
    }
  }, [page, limit, orderBy]);

  useEffect(() => {
    getProjectsPageTotal();
  }, []);

  const [featureProject] = useMutation(FEATURE_PROJECT);
  const [unfeatureProject] = useMutation(UNFEATURE_PROJECT);

  const toggleFeature = async (newData, oldData) => {
    if (newData.isFeatured !== oldData.isFeatured) {
      const { ownerId, id, name, isFeatured } = newData;
      const projectToSubmit = { ownerId, id, name };

      try {
        if (isFeatured) {
          await featureProject({
            variables: projectToSubmit,
          });
          handleOpenSnackbar(
            'success',
            `The project ${name} is featured successfully!`,
          );
        } else {
          await unfeatureProject({
            variables: projectToSubmit,
          });
        }
        getProjectsCb();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit.message);
      }
    }
    return newData;
  };

  const [assignCategoriesProject] = useMutation(ASSIGN_CATEGORIES_PROJECT);
  const [removeCategoriesProject] = useMutation(REMOVE_CATEGORIES_PROJECT);

  const [addSponsorsProject] = useMutation(ADD_SPONSORS_PROJECT);
  const [deleteSponsorsProject] = useMutation(DELETE_SPONSORS_PROJECT);

  const toggleCategories = async (newData, oldData) => {
    if (!_.isEqual(newData.categories, oldData.categories)) {
      const { id } = newData;

      try {
        // First remove
        const catsToRemove = _.differenceWith(
          oldData.categories.map(({ id }) => id),
          newData.categories,
          _.isEqual,
        );

        if (!_.isEmpty(catsToRemove)) {
          await removeCategoriesProject({
            variables: {
              projectId: id,
              categoryIds: [...catsToRemove],
            },
          });
        }

        const catsToAdd = _.differenceWith(
          newData.categories,
          oldData.categories.map(({ id }) => id),
          _.isEqual,
        );

        // Then add
        if (!_.isEmpty(catsToAdd)) {
          await assignCategoriesProject({
            variables: {
              projectId: id,
              categoryIds: catsToAdd.map(({ id }) => id),
            },
          });
        }

        getProjectsCb();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit);
      }
    }
    return newData;
  };

  const toggleSponsors = async (newData, oldData) => {
    if (!_.isEqual(newData.sponsors, oldData.sponsors)) {
      const { id, name } = newData;

      try {
        // First remove
        const sponsToRemove = _.differenceWith(
          oldData.sponsors.map(spon => spon.id),
          newData.sponsors,
          _.isEqual,
        );

        if (!_.isEmpty(sponsToRemove)) {
          await deleteSponsorsProject({
            variables: {
              projectId: id,
              usersIds: sponsToRemove,
            },
          });
        }

        const sponsToAdd = _.differenceWith(
          newData.sponsors,
          oldData.sponsors.map(spon => spon.id),
          _.isEqual,
        );

        // Then add
        if (!_.isEmpty(sponsToAdd)) {
          await addSponsorsProject({
            variables: {
              projectId: id,
              usersIds: sponsToAdd,
            },
          });
        }

        handleOpenSnackbar(
          'success',
          `The project ${name} is edited successfully!`,
        );
        getProjectsCb();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit);
      }
    }
    return newData;
  };

  if (error)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  if (errorTotal)
    return (
      <Alert variant="outlined" severity="error">
        {errorTotal.message}
      </Alert>
    );

  return (
    <Projects
      projects={projects}
      categories={categories}
      sponsors={users}
      loading={loading || loadingTotal}
      toggleFeature={toggleFeature}
      toggleCategories={toggleCategories}
      toggleSponsors={toggleSponsors}
      refetch={getProjectsCb}
      setPage={setPage}
      setLimit={setLimit}
      pageTotalCount={pageTotalCount}
      limit={limit}
      page={page}
      setOrderBy={setOrderBy}
      orderBy={orderBy}
    />
  );
};

ProjectsContainer.propTypes = {
  categories: arrayOf(object),
  projects: arrayOf(object).isRequired,
  users: arrayOf(object).isRequired,
  handleSetProjects: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

ProjectsContainer.defaultProps = {
  categories: [],
};

const mapStateToProps = state => ({
  categories: getCategories(state),
  projects: getProjects(state),
  users: getUsers(state),
});

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleSetProjects: projects => {
    dispatch(setProjects(projects));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
