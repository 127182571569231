/* eslint-disable camelcase */
import auth0 from 'auth0-js';

const webAuth = new auth0.WebAuth({
  domain: process.env.AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENT_ID,
  responseType: 'id_token',
});

const USERNAME_PASSWORD_AUTH = 'Username-Password-Authentication';

export const passwordSignUp = ({ email, username, password, user_metadata }) =>
  new Promise((resolve, reject) => {
    webAuth.signup(
      {
        connection: USERNAME_PASSWORD_AUTH,
        email,
        username,
        password,
        user_metadata,
      },
      (err, data) => {
        if (err) reject(err);
        else resolve(data);
      },
    );
  });

export const passwordSignIn = ({ username, password }) =>
  new Promise((resolve, reject) => {
    webAuth.login(
      {
        realm: USERNAME_PASSWORD_AUTH,
        redirectUri: process.env.AUTH0_REDIRECT_URI,
        username,
        password,
      },
      (err, data) => {
        if (err) reject(err);
        else resolve(data);
      },
    );
  });

export const changePassword = email =>
  new Promise((resolve, reject) => {
    webAuth.changePassword(
      {
        connection: USERNAME_PASSWORD_AUTH,
        email,
      },
      (err, res) => {
        if (err) reject(err);
        else resolve(res);
      },
    );
  });

export const googleSignIn = () => {
  webAuth.authorize({
    connection: 'google-oauth2',
    redirectUri: process.env.AUTH0_REDIRECT_URI,
  });
};

export const logOut = () => {
  webAuth.logout({
    clientID: process.env.AUTH0_CLIENT_ID,
    returnTo: `${window.location.protocol}//${window.location.host}`,
  });
};

export const signUpErrorMessage = err => {
  switch (err.code) {
    case 'invalid_signup':
      return `${err.description}. Username/email is already taken.`;
    case 'missing_property':
      return err.description;
    case 'invalid_password': {
      const policy = err.policy.replace(/\n/gi, '<br>');
      // debugger;
      return `Password is too weak<br>${policy}`;
    }
    default:
      return `Unexpected error: ${err.description}`;
  }
};
