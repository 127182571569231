import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { arrayOf, func, object } from 'prop-types';

import { FormControl, InputLabel, Input, MenuItem } from 'components/base';
import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: theme.palette.common.white,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (id, cats, theme) => {
  return {
    fontWeight: _.find(cats, { id })
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
};

const FilterDropdown = ({ values, onChange, selected }) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="query-filter">Query Filter</InputLabel>
      <Select
        labelId="query-filter"
        id="query-filter"
        value={selected}
        onChange={onChange}
        input={<Input id="select-multiple-project-categories" />}
        MenuProps={MenuProps}
      >
        {values.map(({ id, name }) => (
          <MenuItem key={id} value={id} style={getStyles(id, selected, theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterDropdown.propTypes = {
  values: arrayOf(object).isRequired,
  onChange: func.isRequired,
  selected: object.isRequired,
};

export default FilterDropdown;
