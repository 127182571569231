import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { viewCount: [] };

export const { initViewCount, setViewCount } = createActions({
  INIT_VIEW_COUNT: undefined,
  SET_VIEW_COUNT: viewCount => ({ viewCount }),
});

// REDUCERS
export default handleActions(
  {
    [initViewCount]() {
      return { ...initState };
    },
    [setViewCount](state, { payload: { viewCount } }) {
      return { ...state, viewCount };
    },
  },
  initState,
);

// SELECTORS
const selectorViewCount = state => state.viewCount;

export const getViewCount = createSelector(
  [selectorViewCount],
  viewCount => viewCount.viewCount,
);
