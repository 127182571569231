import React from 'react';
import { arrayOf, object, func } from 'prop-types';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation } from 'react-apollo';

import { GET_USERS } from 'apollo/queries/user-query';

import { getUsers, setUsers } from '../../../../reducers/userReducer';
import { Alert } from '../../../../components';
import {
  TOGGLE_CERTIFY_USER,
  MAKE_PARTNER,
  MAKE_USER,
  MAKE_ADMIN,
} from '../../../../apollo/mutations/user-mutation';
import { openSnackbar } from '../../../../reducers/snackbarReducer';

import Users from './Users';

const UserWrapper = ({ users, handleSetUsers, handleOpenSnackbar }) => {
  const [getUsersCb, { data, loading, error }] = useLazyQuery(GET_USERS, {
    variables: { page: 1, limit: 'FIVE_O' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleSetUsers(data.users);
    },
  });

  const [toggleUserCertified] = useMutation(TOGGLE_CERTIFY_USER);
  const [makeUserPartner] = useMutation(MAKE_PARTNER);
  const [makeUser] = useMutation(MAKE_USER);
  const [makerUserAdmin] = useMutation(MAKE_ADMIN);

  const toggleMentor = async (userId, certified, email) => {
    const unCertifyUserRequest = { id: userId, isCertified: false };
    const certifyUserRequest = { id: userId, isCertified: true };

    try {
      // from certified -> un-certified
      if (!certified) {
        await toggleUserCertified({
          variables: unCertifyUserRequest,
        });
        handleOpenSnackbar('success', `User ${email} is uncertified!`);
      }
      // from un-certified -> certified
      else {
        await toggleUserCertified({
          variables: certifyUserRequest,
        });
        handleOpenSnackbar('success', `User ${email} is certified!`);
      }
      getUsersCb();
    } catch (errorToggleCertify) {
      handleOpenSnackbar('error', errorToggleCertify);
    }
  };

  const toggleUser = async (userId, email) => {
    try {
      await makeUser({
        variables: { id: userId },
      });
      getUsersCb();
      handleOpenSnackbar('success', `User ${email} is now a user!`);
    } catch (errorTogglePartner) {
      handleOpenSnackbar('error', 'Could not make user');
      throw new Error(errorTogglePartner);
    }
  };

  const togglePartner = async (userId, email) => {
    try {
      await makeUserPartner({
        variables: { id: userId },
      });
      getUsersCb();
      handleOpenSnackbar('success', `User ${email} is now a partner!`);
    } catch (errorTogglePartner) {
      handleOpenSnackbar('error', 'Could not make partner');
      throw new Error(errorTogglePartner);
    }
  };

  const toggleAdmin = async (userId, email) => {
    try {
      await makerUserAdmin({
        variables: { id: userId },
      });
      getUsersCb();
      handleOpenSnackbar('success', `User ${email} is now an admin!`);
    } catch (errorToggleAdmin) {
      handleOpenSnackbar('error', 'Could not make admin');
      throw new Error(errorToggleAdmin);
    }
  };

  if (error)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  return (
    <Users
      users={users}
      loading={loading}
      toggleMentor={toggleMentor}
      toggleUser={toggleUser}
      togglePartner={togglePartner}
      toggleAdmin={toggleAdmin}
      refetch={getUsersCb}
    />
  );
};

UserWrapper.propTypes = {
  users: arrayOf(object).isRequired,
  handleSetUsers: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

const mapStateToProps = state => ({
  users: getUsers(state),
});

const mapDispatchToProps = dispatch => ({
  handleSetUsers: users => dispatch(setUsers(users)),
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserWrapper);
