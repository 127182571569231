import { createMuiTheme } from '@material-ui/core/styles';

import typography from './typography';

export default createMuiTheme({
  ...typography,
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#384885',
      dark: '#24305e',
    },
    secondary: {
      main: '#edae49',
      dark: '#D9A900',
    },
    success: {
      main: '#59a76a',
    },
    error: {
      main: '#f26c6c',
    },
    divider: 'rgba(51, 51, 51, 0.3)',
  },
  text: {
    black: '#414042',
    grey: '#989898',
    lightContrast: '#161616',
    lightDefault: '#333333',
    lightLow: '#414141',
    lightSubtle: '#575757',
    lightNonEssential: '#989898',
  },
});
