import React from 'react';

import AppFooter from './AppFooter';

const AppFooterContainer = () => {
  if (window.location.href.indexOf('/signin') > 0) return null;

  return <AppFooter />;
};

export default AppFooterContainer;
