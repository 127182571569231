import React, { useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { arrayOf, object, func } from 'prop-types';

import { Alert } from 'components';
import { openSnackbar } from 'reducers/snackbarReducer';
import { getContests, setContests } from 'reducers/contestReducer';
import { openBackdrop, closeBackdrop } from 'reducers/backdropReducer';
import { getProjects } from 'reducers/projectReducer';
import { GET_CONTESTS } from 'apollo/queries/contests-query';
import {
  TOGGLE_FEATURE_CONTEST,
  UPDATE_CONTEST_DEADLINE,
} from 'apollo/mutations/contest-mutation';

import Contests from './Contests';

const ContestsContainer = ({
  contests,
  projects,
  handleSetContests,
  handleOpenSnackbar,
  handleOpenBackdrop,
  handleCloseBackdrop,
}) => {
  const [showForm, setShowForm] = useState(false);

  const [getContestCb, { data: cbData, loading, error }] = useLazyQuery(
    GET_CONTESTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        handleSetContests(cbData.contests);
      },
      variables: { page: 1, limit: 'FIVE_O', contestName: '' },
    },
  );
  const [toggleFeatureContest] = useMutation(TOGGLE_FEATURE_CONTEST);
  const [updateContestDeadlineM] = useMutation(UPDATE_CONTEST_DEADLINE);

  function refresh() {
    handleOpenSnackbar('success', 'Contest updated successfully!');
    getContestCb();
  }

  async function toggleFeatured(contestId, isFeatured) {
    try {
      await toggleFeatureContest({
        variables: {
          contestId,
          isFeatured,
        },
      });
      refresh();
    } catch (errorFeature) {
      handleOpenSnackbar('error', errorFeature.message);
    }
  }

  async function updateContestDeadline(contestId, newDeadline) {
    try {
      await updateContestDeadlineM({
        variables: {
          contestId,
          contestDeadline: newDeadline,
        },
      });
      refresh();
    } catch (errorContestDeadline) {
      handleCloseBackdrop('error', errorContestDeadline.message);
    }
  }

  if (error)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  return (
    <Contests
      contests={contests}
      loading={loading}
      showForm={showForm}
      setShowForm={setShowForm}
      handleOpenSnackbar={handleOpenSnackbar}
      handleOpenBackdrop={handleOpenBackdrop}
      handleCloseBackdrop={handleCloseBackdrop}
      projects={projects}
      refetch={getContestCb}
      toggleFeatured={toggleFeatured}
      updateContestDeadline={updateContestDeadline}
    />
  );
};

ContestsContainer.propTypes = {
  contests: arrayOf(object).isRequired,
  projects: arrayOf(object).isRequired,
  handleSetContests: func.isRequired,
  handleOpenSnackbar: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
};

const mapStateToProps = state => ({
  contests: getContests(state),
  projects: getProjects(state),
});

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleOpenBackdrop: () => dispatch(openBackdrop()),
  handleCloseBackdrop: () => dispatch(closeBackdrop()),
  handleSetContests: contests => dispatch(setContests(contests)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContestsContainer);
