import React from 'react';
import { useMutation } from 'react-apollo';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from 'components/base';
import { openSnackbar } from 'reducers/snackbarReducer';
import {
  DEACTIVATE_PROJECT,
  REACTIVATE_PROJECT,
} from 'apollo/mutations/project-mutation';
import { closeDialog } from 'reducers/dialogReducer';
import { useUnload } from 'hooks';

const DeactivateDialog = ({
  open,
  options,
  handleCloseDialog,
  handleOpenSnackbar,
}) => {
  // Prevent user reload when dialog is opened
  useUnload(handleCloseDialog);
  const history = useHistory();
  const location = useLocation();

  const {
    project: { ownerId, id, name },
    isReactivate,
  } = options;

  const [deactivateProject] = useMutation(DEACTIVATE_PROJECT);
  const [reactivateProject] = useMutation(REACTIVATE_PROJECT);

  const handleDeactivateProject = async e => {
    e.preventDefault();

    const project = { id, name, ownerId };

    try {
      if (isReactivate) {
        const { data } = await reactivateProject({
          variables: project,
        });

        if (data) {
          handleCloseDialog();
          handleOpenSnackbar(
            'success',
            `The project ${data.reactivateProject.name} is reactivated successfully!`,
          );
          history.push({
            pathname: location.pathname,
            state: { isRefetch: true },
          });
        }
      } else {
        const { data: dataProjectDeactivated } = await deactivateProject({
          variables: project,
        });

        if (dataProjectDeactivated) {
          handleCloseDialog();
          handleOpenSnackbar(
            'success',
            `The project ${dataProjectDeactivated.deactivateProject.name} is deactivated successfully!`,
          );
          history.push({
            pathname: location.pathname,
            state: { isRefetch: true },
          });
        }
      }
    } catch (error) {
      handleOpenSnackbar('error', error.message);
    }
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="deactive-dialog-title"
      aria-describedby="deactive-dialog-description"
    >
      <DialogTitle disableTypography id="deactive-dialog-title">
        <Typography color="error" variant="h6">
          {isReactivate ? 'Reactivate' : 'Deactivate'} Project
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="deactive-dialog-description">
          Are you sure you want to {isReactivate ? 'reactivate' : 'deativate'}{' '}
          this project?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeactivateProject} color="inherit" autoFocus>
            {isReactivate ? 'Reactivate' : 'Deativate'}
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

DeactivateDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleCloseDialog: () => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(DeactivateDialog);
