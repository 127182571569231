import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      backgroundColor: '#01579b',
      color: theme.palette.common.white,
      fontWeight: 700,
      textAlign: 'center',

      '& *, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white,
      },

      '&:first-child': {
        padding: 4,
      },
    },
    '& td > div': {
      justifyContent: 'center',
    },
    '& .Component-horizontalScrollContainer-32': {
      overflow: 'visible !important',
    },
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tag: {
    margin: theme.spacing(0.5),
  },
  button: {
    margin: '25px 0px',
  },
}));

export default useStyles;
