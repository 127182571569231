import gql from 'graphql-tag';

export const CREATE_CONTEST = gql`
  mutation saveNewContest($data: ContestInput!) {
    saveNewContest(data: $data) {
      status
      message
    }
  }
`;

export const ADD_MEMBERS_TO_CONTEST = gql`
  mutation saveContestMembers($membersIds: [ID!]!, $contestId: ID!) {
    saveContestMembers(membersIds: $membersIds, contestId: $contestId) {
      status
      message
    }
  }
`;

export const REMOVE_MEMBERS_FROM_CONTEST = gql`
  mutation removeContestMembers($membersIds: [ID!]!, $contestId: ID!) {
    removeContestMembers(membersIds: $membersIds, contestId: $contestId) {
      status
      message
    }
  }
`;

export const ADD_SPONSORS_TO_CONTEST = gql`
  mutation saveContestSponsors($sponsorsIds: [ID!]!, $contestId: ID!) {
    saveContestSponsors(sponsorsIds: $sponsorsIds, contestId: $contestId) {
      status
      message
    }
  }
`;

export const REMOVE_SPONSORS_FROM_CONTEST = gql`
  mutation removeContestSponsors($sponsorsIds: [ID!]!, $contestId: ID!) {
    removeContestSponsors(sponsorsIds: $sponsorsIds, contestId: $contestId) {
      status
      message
    }
  }
`;

export const ADD_PROJECTS_TO_CONTEST = gql`
  mutation saveContestProjects($projectsIds: [ID!]!, $contestId: ID!) {
    saveContestProjects(projectsIds: $projectsIds, contestId: $contestId) {
      status
      message
    }
  }
`;

export const REMOVE_PROJECTS_FROM_CONTEST = gql`
  mutation removeContestProjects($projectsIds: [ID!]!, $contestId: ID!) {
    removeContestSponsors(projectsIds: $projectsIds, contestId: $contestId) {
      status
      message
    }
  }
`;

export const TOGGLE_FEATURE_CONTEST = gql`
  mutation toggleFeatureContest($contestId: ID!, $isFeatured: Boolean!) {
    toggleFeatureContest(contestId: $contestId, isFeatured: $isFeatured) {
      status
      message
    }
  }
`;

export const UPDATE_CONTEST_DEADLINE = gql`
  mutation updateContestDeadline($contestId: ID!, $contestDeadline: Date!) {
    updateContestDeadline(
      contestId: $contestId
      contestDeadline: $contestDeadline
    ) {
      status
      message
    }
  }
`;
