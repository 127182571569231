import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { contests: [] };

export const { initContests, setContests } = createActions({
  INIT_CONTESTS: undefined,
  SET_CONTESTS: contests => ({ contests }),
});

// REDUCERS
export default handleActions(
  {
    [initContests]() {
      return { ...initState };
    },
    [setContests](state, { payload: { contests } }) {
      return { ...state, contests };
    },
  },
  initState,
);

// SELECTORS
const selectorContest = state => state.contest;

export const getContests = createSelector(
  [selectorContest],
  contest => contest.contests,
);
