import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { partners: [] };

// ACTION CREATORS
export const { initPartners, setPartners } = createActions({
  INIT_PARTNERS: undefined,
  SET_PARTNERS: partners => ({ partners }),
});

// REDUCERS
export default handleActions(
  {
    [initPartners]() {
      return { ...initState };
    },
    [setPartners](state, { payload: { partners } }) {
      return { ...state, partners };
    },
  },
  initState,
);

// SELECTORS
const selectorPartner = state => state.partner;

export const getPartners = createSelector(
  [selectorPartner],
  partner => partner.partners,
);
