import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/base';
import { makeStyles } from 'config/theme';
import { googleSignIn } from 'config/auth/auth0';

import GoogleSvgIcon from './components/GoogleSvgIcon';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const GoogleSignIn = () => {
  const [disabled, toggleDisabled] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const directToGoogleLogin = () => {
    toggleDisabled(true);
    googleSignIn();
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={directToGoogleLogin}
      className={classes.button}
      startIcon={<GoogleSvgIcon />}
      disabled={disabled}
    >
      {t('sign.google')}
    </Button>
  );
};

export default GoogleSignIn;
