import React, { useState } from 'react';
import { arrayOf, object, bool, func } from 'prop-types';
import MaterialTable from 'material-table';
import { FilterList, Language, RefreshOutlined } from '@material-ui/icons';
import { FormControlLabel, Popover, Select, MenuItem } from '@material-ui/core';
import _ from 'lodash';

import { Box, Checkbox } from '../../../../components/base';
import { LightTooltip, LightTooltipImage } from '../../../../components';
import { getImg } from '../../../../helpers/common';
import InputColumns from '../projects/components/InputColumns';
import {
  getLocalStorage,
  KEYS,
  setLocalStorage,
} from '../../../../helpers/localStorage';

import useStyles from './UsersStyle';

const { MAIN_WEB_APP_URL } = process.env;

const Users = ({
  users,
  loading,
  toggleMentor,
  toggleUser,
  togglePartner,
  toggleAdmin,
  refetch,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickFilterColumns = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? 'filter-columns-popover' : undefined;

  const getProfileLink = (userId, firstname, lastname) => {
    firstname = firstname
      ? firstname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .split(' ')
          .join('-')
      : '';
    lastname = lastname
      ? lastname
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .split(' ')
          .join('-')
      : '';

    return `${MAIN_WEB_APP_URL}/users/${userId}/${firstname}-${lastname}/profile`;
  };

  const roles = [
    {
      roleId: 1,
      roleTitle: 'Admin',
    },
    {
      roleId: 2,
      roleTitle: 'User',
    },
    {
      roleId: 3,
      roleTitle: 'Partner',
    },
  ];

  const columns = [
    {
      title: 'ID',
      field: 'id',
      type: 'numeric',
      editable: 'never',
      defaultSort: 'asc',
      cellStyle: { width: 50 },
    },
    {
      title: 'First Name',
      field: 'firstName',
      editable: 'never',
      render: rowData => {
        const { firstName } = rowData;
        return <p>{firstName || 'N/A'}</p>;
      },
    },
    {
      title: 'Last Name',
      field: 'lastName',
      editable: 'never',
      render: rowData => {
        const { lastName } = rowData;
        return <p>{lastName || 'N/A'}</p>;
      },
    },
    {
      title: 'Certified/Mentor',
      field: 'certified',
      type: 'boolean',
      editable: 'onUpdate',
      editComponent: comp => (
        <Checkbox
          checked={comp.value}
          onChange={e => comp.onChange(e.target.checked)}
        />
      ),
    },

    {
      title: 'Role',
      field: 'roleId',
      type: 'numeric',
      render: rowData => (
        <span>
          {roles.find(role => role.roleId === rowData.roleId).roleTitle}
        </span>
      ),
      editable: 'onUpdate',
      editComponent: comp => (
        <Select
          className={classes.select}
          value={comp.value}
          onChange={e => comp.onChange(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {roles.map(role => (
            <MenuItem key={role.roleId} value={role.roleId}>
              {role.roleTitle}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'never',
      cellStyle: { width: 50 },
      render: rowData => {
        const { email } = rowData;
        return (
          <LightTooltip placement="left" title={`Send mail to ${email}`}>
            <a href={`mailto:${email}`}>
              <p>{email}</p>
            </a>
          </LightTooltip>
        );
      },
    },
    {
      title: 'Profile',
      field: 'avatarLink',
      editable: 'never',
      render: rowData => {
        const { id: userId, firstname, lastname, avatarLink, email } = rowData;
        return avatarLink ? (
          <LightTooltipImage title={`Go to ${email} profile`} placement="right">
            <a
              target="_blank"
              rel="noreferrer"
              href={getProfileLink(userId, firstname, lastname)}
            >
              <img
                style={{ width: 75, height: 75 }}
                src={getImg(avatarLink)}
                alt="user-avatar"
              />
            </a>
          </LightTooltipImage>
        ) : (
          'No image'
        );
      },
    },
    {
      title: 'Created At',
      field: 'createdAt',
      type: 'date',
      editable: 'never',
    },
  ];

  const options = {
    // filtering: true,
    pageSize: 10,
    pageSizeOptions: [10, 20, 30],
    showTitle: false,
    padding: 'dense',
    headerStyle: {
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#384885',
    },
    cellStyle: {
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    toolbarButtonAlignment: 'left',
  };

  const editable = {
    onRowUpdate: async (newData, oldData) => {
      const { certified: oldCertified, roleId: oldRoleId } = oldData;
      const { id: userId, email, certified, roleId } = newData;
      if (oldCertified !== certified) {
        toggleMentor(userId, certified, email);
      }
      if (oldRoleId !== roleId) {
        if (roleId === 1) {
          toggleAdmin(userId, email);
        } else if (roleId === 2) {
          toggleUser(userId, email);
        } else {
          togglePartner(userId, email);
        }
      }
    },
  };

  const actions = [
    {
      icon: () => <RefreshOutlined />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
    {
      icon: () => <FilterList />,
      tooltip: 'Hide/Unhide columns',
      isFreeAction: true,
      onClick: handleClickFilterColumns,
    },
    {
      icon: () => <Language />,
      tooltip: 'Go to profile',
      onClick: (event, { id: userId, firstname, lastname }) => {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: getProfileLink(userId, firstname, lastname),
        }).click();
      },
    },
  ];

  // COLUMNS HANDLING
  const columnsString = getLocalStorage(KEYS.adminCocoonUserColumns);

  const [selectedColumns, setSelectedColumns] = useState(
    columnsString ? JSON.parse(columnsString) : columns.map(col => col.field),
  );

  const isAllColumns = selectedColumns.length === columns.length;
  const hasColumns =
    !_.isEmpty(selectedColumns) && selectedColumns.length !== columns.length;

  const selectColumnsHelper = cols => {
    setSelectedColumns(cols);
    setLocalStorage(KEYS.adminCocoonUserColumns, JSON.stringify(cols));
  };

  const handleSelectColumns = ({ target: { value } }) => {
    selectColumnsHelper(value);
  };

  const handleToggleAllColumns = () => {
    if (isAllColumns) {
      selectColumnsHelper([]);
    } else {
      selectColumnsHelper(columns.map(col => col.field));
    }
  };

  return (
    <Box className={classes.table}>
      <MaterialTable
        columns={columns.filter(col => selectedColumns.includes(col.field))}
        data={users}
        title="Users"
        options={options}
        isLoading={loading}
        editable={editable}
        actions={actions}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.filterColumns}>
          <InputColumns
            value={selectedColumns}
            onChange={handleSelectColumns}
            columns={columns}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllColumns}
                onChange={handleToggleAllColumns}
                name="checkedB"
                color="primary"
                indeterminate={hasColumns}
              />
            }
            label="All columns"
          />
        </Box>
      </Popover>
    </Box>
  );
};

Users.propTypes = {
  users: arrayOf(object).isRequired,
  loading: bool.isRequired,
  toggleMentor: func.isRequired,
  toggleUser: func.isRequired,
  togglePartner: func.isRequired,
  toggleAdmin: func.isRequired,
  refetch: func.isRequired,
};

export default Users;
