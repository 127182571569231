import gql from 'graphql-tag';

export const GET_PARTNERS = gql`
  query partners($name: String!) {
    partners(name: $name) {
      id
      username
      email
    }
  }
`;

export const GET_USERS_BY_USERNAME_EMAIL = gql`
  query usersByUsernameEmail(
    $page: Int!
    $limit: PaginationLimit!
    $orderBy: UserOrderBy!
    $searchTerm: String
    $isPartner: Boolean
  ) {
    usersByUsernameEmail(
      limit: $limit
      orderBy: $orderBy
      page: $page
      searchTerm: $searchTerm
      isPartner: $isPartner
    ) {
      id
      email
    }
  }
`;

export const GET_USERS = gql`
  query users($page: Int!, $limit: PaginationLimit) {
    users(page: $page, limit: $limit) {
      id
      username
      email
      firstName
      lastName
      avatarLink
      certified
      createdAt
      roleId
    }
  }
`;

export const GET_USER = gql`
  query userById($id: ID!) {
    userById(id: $id) {
      id
      username
      email
      firstName
      lastName
      gender
      dob
      description
      avatarLink
      backgroundPictureUrl
      primaryJob
      location
      fullBio
      fieldsOfWork
      createdAt
      experiences {
        id
        role
        company
        duration
        description
        startDate
        endDate
      }
      contests {
        id
        name
        reward
        year
        description
      }
      linkedin
      github
      facebook
      twitter
      country {
        id
        iso
        name
      }
      state {
        id
        iso
        name
      }
    }
  }
`;
