import gql from 'graphql-tag';

const FRAGMENT_PROJECT = gql`
  fragment mainDetails on Project {
    id
    name
    description
    assetLink
    ownerId
    createdAt
    updatedAt
    isFeatured
    isArchived
    categories {
      id
      name
      displayColor
    }
    views
    projectOverview
    tags {
      id
      tagName
    }
    sponsors {
      id
      firstName
      lastName
      email
    }
    owner {
      id
      username
      email
      firstName
      lastName
      avatarLink
    }
  }
`;

export const GET_SEARCH_PROJECTS = gql`
  query projects($page: Int!, $limit: PaginationLimit, $projectName: String) {
    projects(projectName: $projectName, page: $page, limit: $limit) {
      id
      name
    }
  }
`;

export const GET_PROJECTS = gql`
  query projects(
    $page: Int!
    $limit: PaginationLimit
    $projectName: String
    $filter: String
  ) {
    projects(
      projectName: $projectName
      page: $page
      limit: $limit
      filter: $filter
    ) {
      ...mainDetails
    }
  }
  ${FRAGMENT_PROJECT}
`;

export const GET_PROJECTS_PAGE_TOTAL = gql`
  query ProjectsPageTotal($limit: PaginationLimit) {
    projectsPageTotal(limit: $limit) {
      total
    }
  }
`;

export const GET_PROJECT = gql`
  query projectById($id: ID!, $isGuest: Boolean) {
    projectById(id: $id, isGuest: $isGuest) {
      ...mainDetails
      members {
        id
        username
        email
        firstName
        lastName
        avatarLink
      }
    }
  }
  ${FRAGMENT_PROJECT}
`;
