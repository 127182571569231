import React from 'react';

import { object, arrayOf, bool, func } from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useTheme } from 'config/theme';
import { Chip, Box, Checkbox, LightTooltip } from 'components';
import { Button } from 'components/base';
import DateFnsUtils from '@date-io/date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import CreateContest from './components/CreateContest';
import useStyles from './ContestsStyle';

const options = {
  sorting: true,
  filtering: true,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  showTitle: false,
  toolbarButtonAlignment: 'left',
  padding: 'dense',
  addRowPosition: 'first',
  headerStyle: {
    width: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: '#384885',
  },
};

const Contests = ({
  projects,
  refetch,
  contests,
  loading,
  showForm,
  setShowForm,
  handleOpenSnackbar,
  handleOpenBackdrop,
  handleCloseBackdrop,
  toggleFeatured,
  updateContestDeadline,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const columns = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      type: 'numeric',
    },
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
    },
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Featured',
      field: 'isFeatured',
      type: 'boolean',
      editable: 'onUpdate',
      editComponent: comp => (
        <Checkbox
          checked={comp.value}
          onChange={e => comp.onChange(e.target.checked)}
        />
      ),
    },
    {
      title: 'Tags',
      field: 'tags',
      editable: 'never',
      render: rowData => {
        const renderTags = no => {
          const arrays = no ? rowData.tags.slice(0, no) : rowData.tags;

          return arrays.map(tag => (
            <Chip
              key={tag.id}
              label={tag.tagName}
              size="small"
              style={{
                backgroundColor: '#fff',
                color: '#000',
                margin: 2,
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          ));
        };
        return (
          <Box className={classes.tags}>
            {renderTags(3)}
            {rowData.tags.length > 3 && (
              <LightTooltip
                title={<Box width={200}>{renderTags()}</Box>}
                placement="right"
              >
                <Chip
                  label="View more..."
                  size="small"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    margin: 2,
                  }}
                />
              </LightTooltip>
            )}
          </Box>
        );
      },
    },
    {
      title: 'Creator',
      field: 'creator',
      editable: 'never',
      render: rowData => {
        return (
          <Chip
            label={rowData.creator.email}
            size="small"
            style={{
              backgroundColor: '#fff',
              color: '#000',
              margin: 2,
              border: `2px solid ${theme.palette.primary.main}`,
            }}
          />
        );
      },
    },
    {
      title: 'Members',
      field: 'members',
      editable: 'never',
      render: rowData => {
        const renderTags = no => {
          const arrays = no ? rowData.members.slice(0, no) : rowData.members;

          return arrays.map(member => (
            <Chip
              key={member.id}
              label={member.email}
              size="small"
              style={{
                backgroundColor: '#fff',
                color: '#000',
                margin: 2,
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          ));
        };
        return (
          <Box className={classes.tags}>
            {renderTags(3)}
            {rowData.members.length > 3 && (
              <LightTooltip
                title={<Box width={200}>{renderTags()}</Box>}
                placement="right"
              >
                <Chip
                  label="View more..."
                  size="small"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    margin: 2,
                  }}
                />
              </LightTooltip>
            )}
          </Box>
        );
      },
    },
    {
      title: 'Projects',
      field: 'projects',
      editable: 'never',
      render: rowData => {
        const renderProjects = no => {
          const arrays = no ? rowData.projects.slice(0, no) : rowData.projects;

          return arrays.map(project => (
            <Chip
              key={project.id}
              label={project.name}
              size="small"
              style={{
                backgroundColor: '#fff',
                color: '#000',
                margin: 2,
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          ));
        };
        return (
          <Box className={classes.tags}>
            {renderProjects(3)}
            {rowData.members.length > 3 && (
              <LightTooltip
                title={<Box width={200}>{renderProjects()}</Box>}
                placement="right"
              >
                <Chip
                  label="View more..."
                  size="small"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    margin: 2,
                  }}
                />
              </LightTooltip>
            )}
          </Box>
        );
      },
    },
    {
      title: 'Contest Deadline',
      field: 'contestDeadline',
      isEditable: rowData => rowData.contestDeadline,
      editable: 'onUpdate',
      type: 'datetime',
      editComponent: comp => {
        const format = 'yyyy-MM-dd HH:mm:ss';
        const datetime = !comp.value
          ? new Date()
          : formatInTimeZone(comp.value, 'Asia/Ho_Chi_Minh', format);

        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              id="contestDeadline"
              showTodayButton
              type="datetime-local"
              value={datetime}
              format="yyyy-MM-dd'T'hh:mm"
              onChange={e => comp.onChange(e.toISOString())}
            />
          </MuiPickersUtilsProvider>
        );
      },
    },
  ];

  const editable = {
    onRowUpdate: async (newData, oldData) => {
      const { isFeatured, contestDeadline } = oldData;
      const {
        id: contestId,
        isFeatured: newFeaturedStatus,
        contestDeadline: newContestDeadline,
      } = newData;

      if (isFeatured !== newFeaturedStatus) {
        toggleFeatured(contestId, newFeaturedStatus);
      }

      if (contestDeadline !== newContestDeadline) {
        updateContestDeadline(contestId, newContestDeadline);
      }
    },
  };

  const actions = [
    {
      icon: () => <RefreshIcon />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
  ];

  return (
    <Box className={classes.table}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowForm(!showForm)}
        className={classes.button}
      >
        {showForm ? 'Back' : '+ Create Contest'}
      </Button>
      {showForm ? (
        <CreateContest
          handleOpenSnackbar={handleOpenSnackbar}
          handleOpenBackdrop={handleOpenBackdrop}
          handleCloseBackdrop={handleCloseBackdrop}
          setShowForm={setShowForm}
          projects={projects}
          refetch={refetch}
        />
      ) : (
        <MaterialTable
          data={[...contests]}
          title="Contests"
          isLoading={loading}
          columns={columns}
          options={options}
          editable={editable}
          actions={actions}
        />
      )}
    </Box>
  );
};

Contests.propTypes = {
  projects: arrayOf(object).isRequired,
  contests: arrayOf(object).isRequired,
  refetch: func.isRequired,
  loading: bool.isRequired,
  showForm: bool.isRequired,
  setShowForm: func.isRequired,
  handleOpenSnackbar: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  toggleFeatured: func.isRequired,
  updateContestDeadline: func.isRequired,
};

export default Contests;
