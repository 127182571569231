import React, { useState } from 'react';
import { arrayOf, object, bool, func, number, string } from 'prop-types';
import MaterialTable from 'material-table';
import { FormControlLabel, Popover, TablePagination } from '@material-ui/core';
import {
  FilterList,
  Language,
  RefreshOutlined,
  FindInPage,
} from '@material-ui/icons';
import _ from 'lodash';

import { makeStyles, useTheme } from 'config/theme';
import { getImg } from 'helpers/common';
import {
  Checkbox,
  Chip,
  Box,
  Truncate,
  LightTooltip,
  LightTooltipImage,
} from 'components';
import { KEYS, getLocalStorage, setLocalStorage } from 'helpers/localStorage';

import InputCategories from './components/InputCategories';
import InputSponsors from './components/InputSponsors';
import RichTextLink from './components/RichTextLink';
import InputColumns from './components/InputColumns';
import FilterDropdown from './components/FilterDropdown';

const { MAIN_WEB_APP_URL } = process.env;

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    flex: 1,
    overflowY: 'auto',
    '& th': {
      backgroundColor: '#01579b',
      color: theme.palette.common.white,
      fontWeight: 700,
      textAlign: 'center',

      '& *, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white,
      },

      '&:first-child': {
        padding: 4,
      },
    },
    '& td > div': {
      justifyContent: 'center',
    },
    '& .Component-horizontalScrollContainer-119': {
      overflow: 'visible !important',
    },
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tag: {
    margin: theme.spacing(0.5),
  },
  owner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,

    '& div': {
      margin: theme.spacing(0.5, 1),
    },
  },
  filterColumns: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '& > label': {
      marginLeft: 0,
    },
  },
}));

const Projects = ({
  projects,
  categories,
  sponsors,
  loading,
  toggleFeature,
  toggleCategories,
  toggleSponsors,
  refetch,
  setLimit,
  setPage,
  pageTotalCount,
  limit,
  page,
  setOrderBy,
  orderBy,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchor, setFilterAnchor] = useState(null);

  const limitCount = limit === 'TWO_FIVE' ? 25 : limit === 'FIVE_O' ? 50 : 10;

  const handleClickFilterColumns = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickQueryFilter = e => {
    setFilterAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseFilter = () => {
    setFilterAnchor(null);
  };

  const getProjectLink = (id, projectName) => {
    projectName = projectName
      .toLowerCase()
      .split(' ')
      .join('-');

    return `${MAIN_WEB_APP_URL}/projects/${id}/${projectName}`;
  };

  const open = !!anchorEl;
  const openFilter = !!filterAnchor;
  const id = open ? 'filter-columns-popover' : undefined;
  const idQuery = openFilter ? 'filter-query-popover' : undefined;

  const columns = [
    {
      title: 'ID',
      field: 'id',
      type: 'numeric',
      editable: 'never',
    },
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      render: rowData => {
        const { id: projectId, name } = rowData;
        return (
          <LightTooltip title={`Go to ${name}`}>
            <a
              target="_blank"
              rel="noreferrer"
              href={getProjectLink(projectId, name)}
            >
              <Truncate lines={1} width={200}>
                {rowData.name}
              </Truncate>
            </a>
          </LightTooltip>
        );
      },
    },
    {
      title: 'Archived',
      field: 'isArchived',
      type: 'boolean',
      editable: 'never',
    },
    {
      title: 'Featured',
      field: 'isFeatured',
      type: 'boolean',
      editable: 'onUpdate',
      editComponent: comp => (
        <Checkbox
          checked={comp.value}
          onChange={e => {
            comp.onChange(e.target.checked);
          }}
        />
      ),
    },
    {
      title: 'Owner',
      field: 'owner',
      editable: 'never',
      render: rowData => {
        return (
          <LightTooltip
            title={
              <Box className={classes.owner}>
                <div>
                  <b>ID:</b> {rowData.owner.id}
                </div>
                <div>
                  <b>Username:</b> {rowData.owner.username}
                </div>
                <div>
                  <b>Name:</b> {rowData.owner.firstname}{' '}
                  {rowData.owner.lastname}
                </div>
                <div>
                  <b>Email:</b> {rowData.owner.email}
                </div>
              </Box>
            }
          >
            <Truncate lines={2} width={200}>
              {rowData.owner.firstname} {rowData.owner.lastname}
            </Truncate>
          </LightTooltip>
        );
      },
    },
    {
      title: 'Categories',
      field: 'categories',
      editComponent: comp => (
        <InputCategories
          value={comp.value}
          onChange={comp.onChange}
          categories={categories}
        />
      ),
      render: rowData => {
        const renderCats = no => {
          const arrays = no
            ? rowData.categories.slice(0, no)
            : rowData.categories;

          return arrays.map(cat => (
            <Chip
              key={cat.id}
              label={cat.name}
              size="small"
              style={{
                backgroundColor: cat.displayColor,
                color: '#fff',
                margin: 2,
              }}
            />
          ));
        };
        return (
          <Box className={classes.tags}>
            {renderCats(3)}
            {rowData.categories.length > 3 && (
              <LightTooltip
                title={<Box width={200}>{renderCats()}</Box>}
                placement="right"
              >
                <Chip
                  label="View more..."
                  size="small"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    margin: 2,
                  }}
                />
              </LightTooltip>
            )}
          </Box>
        );
      },
      editable: 'onUpdate',
    },
    {
      title: 'Tags',
      field: 'tags',
      render: rowData => {
        const renderTags = no => {
          const arrays = no ? rowData.tags.slice(0, no) : rowData.tags;

          return arrays.map(tag => (
            <Chip
              key={tag.id}
              label={tag.tagName}
              size="small"
              style={{
                backgroundColor: '#fff',
                color: '#000',
                margin: 2,
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          ));
        };
        return (
          <Box className={classes.tags}>
            {renderTags(3)}
            {rowData.tags.length > 3 && (
              <LightTooltip
                title={<Box width={200}>{renderTags()}</Box>}
                placement="right"
              >
                <Chip
                  label="View more..."
                  size="small"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    margin: 2,
                  }}
                />
              </LightTooltip>
            )}
          </Box>
        );
      },
      editable: 'never',
    },
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
      render: rowData => (
        <LightTooltip title={rowData.description}>
          <Truncate lines={2} width={400}>
            {rowData.description}
          </Truncate>
        </LightTooltip>
      ),
      cellStyle: {
        maxWidth: 500,
      },
    },
    {
      title: 'Project Overview',
      field: 'projectOverview',
      editable: 'never',
      render: rowData => <RichTextLink text={rowData.projectOverview || ''} />,
    },
    {
      title: 'Cover Image',
      field: 'assetLink',
      render: rowData => {
        const src = getImg(rowData.assetLink);
        const alt = rowData.name;
        return rowData.assetLink ? (
          <LightTooltipImage
            title={<img src={src} alt={alt} style={{ height: 250 }} />}
            placement="right"
          >
            <img src={src} alt={alt} style={{ height: 100 }} />
          </LightTooltipImage>
        ) : (
          'No image'
        );
      },
      cellStyle: {
        textAlign: 'center',
      },
      editable: 'never',
    },
    {
      title: 'Sponsors',
      field: 'sponsors',
      editComponent: comp => (
        <InputSponsors
          value={comp.value.map(val => val.id)}
          onChange={comp.onChange}
          sponsors={sponsors}
        />
      ),
      render: rowData => {
        const renderSponsors = no => {
          const arrays = no ? rowData.sponsors.slice(0, no) : rowData.sponsors;

          return arrays.map(spon => (
            <Chip
              key={spon.id}
              label={`${spon.email}`}
              size="small"
              style={{
                backgroundColor: 'rgba(0,0,0,.5)',
                color: '#fff',
                margin: 2,
              }}
            />
          ));
        };
        return (
          <Box className={classes.tags}>
            {renderSponsors(3)}
            {rowData.sponsors.length > 3 && (
              <LightTooltip
                title={<Box width={200}>{renderSponsors()}</Box>}
                placement="right"
              >
                <Chip
                  label="View more..."
                  size="small"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    margin: 2,
                  }}
                />
              </LightTooltip>
            )}
          </Box>
        );
      },
      editable: 'onUpdate',
    },
    { title: 'Views', field: 'views', type: 'numeric', editable: 'never' },
    {
      title: 'Created At',
      field: 'createdAt',
      type: 'date',
      editable: 'never',
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      type: 'date',
      editable: 'never',
    },
  ];

  const options = {
    sorting: true,
    filtering: true,
    pageSize: limitCount,
    pageSizeOptions: [10, 25, 50],
    showTitle: false,
    toolbarButtonAlignment: 'left',
    padding: 'dense',
    addRowPosition: 'first',
    headerStyle: {
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#384885',
    },
    cellStyle: {
      width: '100%',
      whiteSpace: 'nowrap',
    },
    emptyRowsWhenPaging: false,
    minRows: 0,
  };

  const editable = {
    onRowUpdate: async (newData, oldData) => {
      toggleFeature(newData, oldData);
      toggleCategories(newData, oldData);
      toggleSponsors(newData, oldData);
    },
  };

  const actions = [
    {
      icon: () => <RefreshOutlined />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
    {
      icon: () => <FilterList />,
      tooltip: 'Filter columns',
      isFreeAction: true,
      onClick: handleClickFilterColumns,
    },
    {
      icon: () => <FindInPage />,
      tooltip: 'Query Filter',
      isFreeAction: true,
      onClick: handleClickQueryFilter,
    },
    {
      icon: () => <Language />,
      tooltip: 'Go to project',
      onClick: (event, { id: projectId, name }) => {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: getProjectLink(projectId, name),
        }).click();
      },
    },
  ];

  // COLUMNS HANDLING
  const columnsString = getLocalStorage(KEYS.adminCocoonProjectColumns);

  const [selectedColumns, setSelectedColumns] = useState(
    columnsString ? JSON.parse(columnsString) : columns.map(col => col.field),
  );

  const filters = [
    {
      id: 'DATE_CREATED_ASC',
      name: 'Date Created ASC',
    },
    {
      id: 'DATE_CREATED_DESC',
      name: 'Date Created DESC',
    },
    {
      id: 'DATE_UPDATED_ASC',
      name: 'Date Updated ASC',
    },
    {
      id: 'DATE_UPDATED_DESC',
      name: 'Date Updated DESC',
    },
    {
      id: 'VIEWS_ASC',
      name: 'Views ASC',
    },
    {
      id: 'VIEWS_DESC',
      name: 'Views DESC',
    },
  ];

  const queryFilterLocal = getLocalStorage(KEYS.adminCocoonQueryFilter);
  const [selectedQueryFilter, setSelectedQueryFilter] = useState(
    queryFilterLocal ? JSON.parse(queryFilterLocal) : 'DATE_CREATED_DESC',
  );

  const isAllColumns = selectedColumns.length === columns.length;
  const hasColumns =
    !_.isEmpty(selectedColumns) && selectedColumns.length !== columns.length;

  const selectColumnsHelper = cols => {
    setSelectedColumns(cols);
    setLocalStorage(KEYS.adminCocoonProjectColumns, JSON.stringify(cols));
  };

  const selectQueryFilter = query => {
    setOrderBy(query);
    setSelectedQueryFilter(query);
    setLocalStorage(KEYS.adminCocoonQueryFilter, JSON.stringify(query));
  };

  const handleSelectColumns = ({ target: { value } }) => {
    selectColumnsHelper(value);
  };

  const handleSelectFilter = ({ target: { value } }) => {
    selectQueryFilter(value);
    setOrderBy(value);
  };

  const handleToggleAllColumns = () => {
    if (isAllColumns) {
      selectColumnsHelper([]);
    } else {
      selectColumnsHelper(columns.map(col => col.field));
    }
  };

  const onChangePage = (event, pageSize) => {
    setPage(pageSize + 1);
  };

  const onChangeRowsPerPage = event => {
    const pageSize = event.target.value;
    if (pageSize === 25) {
      setLimit('TWO_FIVE');
    } else if (pageSize === 50) {
      setLimit('FIVE_O');
    } else {
      setLimit('TEN');
    }
  };

  return (
    <div className={classes.wrapper}>
      <Box className={classes.table}>
        <MaterialTable
          minRows={0}
          data={projects}
          title="Projects"
          isLoading={loading}
          columns={columns.filter(col => selectedColumns.includes(col.field))}
          options={options}
          editable={editable}
          actions={actions}
          initialPage={0}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page - 1}
          components={{
            Pagination: props => (
              <TablePagination
                {...props}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={limitCount}
                count={pageTotalCount * limitCount}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                page={page - 1}
                minRows={0}
              />
            ),
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={classes.filterColumns}>
            <InputColumns
              value={selectedColumns}
              onChange={handleSelectColumns}
              columns={columns}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllColumns}
                  onChange={handleToggleAllColumns}
                  name="checkedB"
                  color="primary"
                  indeterminate={hasColumns}
                />
              }
              label="All columns"
            />
          </Box>
        </Popover>
        <Popover
          id={idQuery}
          open={openFilter}
          anchorEl={filterAnchor}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={classes.filterColumns}>
            <FilterDropdown
              values={filters}
              onChange={handleSelectFilter}
              selected={selectedQueryFilter}
            />
          </Box>
        </Popover>
      </Box>
    </div>
  );
};

Projects.propTypes = {
  loading: bool.isRequired,
  projects: arrayOf(object).isRequired,
  categories: arrayOf(object),
  sponsors: arrayOf(object).isRequired,
  toggleFeature: func.isRequired,
  toggleCategories: func.isRequired,
  toggleSponsors: func.isRequired,
  refetch: func.isRequired,
  setLimit: func.isRequired,
  setPage: func.isRequired,
  pageTotalCount: number.isRequired,
  limit: string.isRequired,
  page: number.isRequired,
  setOrderBy: func.isRequired,
  orderBy: string.isRequired,
};

Projects.defaultProps = {
  categories: [],
};

export default Projects;
