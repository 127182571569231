import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useLazyQuery, useMutation } from 'react-apollo';

import {
  TextField,
  Typography,
  Button,
  Chip,
  Autocomplete,
  CircularProgress,
} from 'components/base';
import { CREATE_PARTNER } from 'apollo/mutations/partner-mutation';
import { GET_USERS_BY_USERNAME_EMAIL } from 'apollo/queries/user-query';
import { useDebounce } from 'hooks';

import useStyles from './CreatePartnerStyle';

const CreatePartner = ({
  refetch,
  handleOpenSnackbar,
  handleOpenBackdrop,
  handleCloseBackdrop,
  setShowForm,
}) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  const [currentTag, setCurrentTag] = useState('');
  const [tags, setTags] = useState([]);

  const [fieldOfWork, setFieldOfWork] = useState('');
  const [fieldsOfWork, setFieldsOfWork] = useState([]);

  const [searchMember, setSearchMember] = useState('');
  const debouncedSearchMember = useDebounce(searchMember, 500);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [
    getUsersByUsernameEmailCb,
    { data: usersData, loading: loadingUsers },
  ] = useLazyQuery(GET_USERS_BY_USERNAME_EMAIL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (usersData) {
      setMemberOptions(usersData.usersByUsernameEmail);
    }
  }, [usersData]);

  useEffect(() => {
    if (debouncedSearchMember) {
      getUsersByUsernameEmailCb({
        variables: {
          searchTerm: debouncedSearchMember,
          orderBy: ['id'],
          limit: 100,
        },
      });
    }
  }, [debouncedSearchMember]);

  const [createPartner] = useMutation(CREATE_PARTNER);

  const onSubmit = async data => {
    try {
      handleOpenBackdrop();

      const partnerRequest = {
        partnerName: data.partnerName || '',
        username: data.username || '',
        email: data.email || '',
        description: data.description || '',
        fullBio: data.fullBio || '',
        tags,
        fieldsOfWork,
        membersIds: selectedMembers.map(({ id }) => id),
        ...data,
      };
    } catch (error) {
      handleCloseBackdrop();
      handleOpenSnackbar(error.message, 'error');
    }
  };

  const handleAddNewTag = isTag => {
    if (isTag) {
      setTags([...tags, { id: tags.length + 1, tagName: currentTag }]);
      setCurrentTag('');
    } else {
      setFieldsOfWork([
        ...fieldsOfWork,
        { id: fieldsOfWork.length + 1, fieldName: fieldOfWork },
      ]);
      setFieldOfWork('');
    }
  };

  const handleDeleteTag = (isTag, tag) => {
    const { id } = tag;
    if (isTag) {
      const newTags = tags.filter(item => item.id !== id);
      setTags(newTags);
    } else {
      const newFields = fieldsOfWork.filter(item => item.id !== id);
      setFieldsOfWork(newFields);
    }
  };

  return (
    <Grid container className={classes.container}>
      <form className={classes.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <Typography className={classes.formTitle} variant="h2" component="h2">
          Create Partner Form
        </Typography>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                className={classes.textFieldLabel}
                variant="h4"
                component="h4"
              >
                Partner Name
              </Typography>
              <TextField
                {...register('partnerName')}
                placeholder="Enter contest name"
                variant="outlined"
                fullWidth
                className={classes.textField}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography
                className={classes.textFieldLabel}
                variant="h4"
                component="h4"
              >
                Username
              </Typography>
              <TextField
                {...register('username')}
                placeholder="Enter username"
                variant="outlined"
                fullWidth
                className={classes.textField}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography
                className={classes.textFieldLabel}
                variant="h4"
                component="h4"
              >
                Email
              </Typography>
              <TextField
                {...register('email')}
                placeholder="Enter email"
                variant="outlined"
                fullWidth
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                className={classes.textFieldLabel}
                variant="h4"
                component="h4"
              >
                Description
              </Typography>
              <TextField
                {...register('description')}
                placeholder="Enter description"
                multiline
                variant="outlined"
                fullWidth
                className={classes.textField}
                rows={3}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography
                className={classes.textFieldLabel}
                variant="h4"
                component="h4"
              >
                Full Bio
              </Typography>
              <TextField
                {...register('fullBio')}
                placeholder="Enter Full Bio"
                multiline
                variant="outlined"
                fullWidth
                className={classes.textField}
                rows={3}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.textFieldLabel}
              variant="h4"
              component="h4"
            >
              Github
            </Typography>
            <TextField
              {...register('github')}
              placeholder="Enter Github"
              variant="outlined"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.textFieldLabel}
              variant="h4"
              component="h4"
            >
              LinkedIn
            </Typography>
            <TextField
              {...register('linkedin')}
              placeholder="Enter LinkedIn"
              variant="outlined"
              fullWidth
              className={classes.textField}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.textFieldLabel}
              variant="h4"
              component="h4"
            >
              Facebook
            </Typography>
            <TextField
              {...register('facebook')}
              placeholder="Enter Facebook"
              variant="outlined"
              fullWidth
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography
              className={classes.textFieldLabel}
              variant="h4"
              component="h4"
            >
              Twitter
            </Typography>
            <TextField
              {...register('twitter')}
              placeholder="Enter Twitter"
              variant="outlined"
              fullWidth
              className={classes.textField}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.textFieldLabel}
              variant="h4"
              component="h4"
            >
              Tags
            </Typography>
            <TextField
              value={currentTag.replace(/\n/g, '')}
              onChange={e => setCurrentTag(e.target.value)}
              placeholder="Enter tag name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              onKeyDown={e => e.keyCode === 13 && handleAddNewTag(true)}
              multiline
            />

            {tags.length > 0 && (
              <div className={classes.row}>
                {tags.map(tag => (
                  <Chip
                    className={classes.chip}
                    key={tag.id}
                    label={tag.tagName}
                    onDelete={() => handleDeleteTag(true, tag)}
                  />
                ))}
              </div>
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography
              className={classes.textFieldLabel}
              variant="h4"
              component="h4"
            >
              Fields of Work
            </Typography>
            <TextField
              value={fieldOfWork.replace(/\n/g, '')}
              onChange={e => setFieldOfWork(e.target.value)}
              placeholder="Enter field of work"
              variant="outlined"
              fullWidth
              className={classes.textField}
              onKeyDown={e => e.keyCode === 13 && handleAddNewTag(false)}
              multiline
            />

            {fieldsOfWork.length > 0 && (
              <div className={classes.row}>
                {fieldsOfWork.map(field => (
                  <Chip
                    className={classes.chip}
                    key={field.id}
                    label={field.fieldName}
                    onDelete={() => handleDeleteTag(false, field)}
                  />
                ))}
              </div>
            )}
          </Grid>
        </Grid>

        <Typography
          className={classes.textFieldLabel}
          variant="h4"
          component="h4"
        >
          Add Members
        </Typography>

        <Autocomplete
          multiple
          value={selectedMembers}
          id="asynchronous-members"
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(event, value) => setSelectedMembers([...value])}
          getOptionLabel={({ email }) => email}
          options={memberOptions || []}
          loading={loadingUsers}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.textField}
              placeholder="Search for a partner"
              variant="outlined"
              onChange={e => setSearchMember(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <Button
          className={classes.submit}
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
        >
          Submit
        </Button>
      </form>
    </Grid>
  );
};

CreatePartner.propTypes = {
  refetch: func.isRequired,
  handleOpenSnackbar: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  setShowForm: func.isRequired,
};

export default CreatePartner;
