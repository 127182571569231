import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';

import { getDialogState } from 'reducers/dialogReducer';

import { DIALOG } from '../../helpers/constants';

import ListDialog from './list/ListDialog';
import DeactivateDialog from './deactive/DeactivateDialog';
import DeleteDialog from './delete/DeleteDialog';
import LogoutDialog from './logout/LogoutDialog';

const Dialogs = {
  [DIALOG.LIST]: ListDialog,
  [DIALOG.DELETE]: DeleteDialog,
  [DIALOG.DEACTIVATE]: DeactivateDialog,
  [DIALOG.LOGOUT]: LogoutDialog,
};

const CocoonDialog = ({ dialog: { type, ...rest } }) => {
  let Dialog = ListDialog;

  if (type) {
    Dialog = Dialogs[type];
  }

  return <Dialog {...rest} />;
};

CocoonDialog.propTypes = {
  dialog: object.isRequired,
};

const mapStateToProps = state => ({
  dialog: getDialogState(state),
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(CocoonDialog);
