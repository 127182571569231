import { makeStyles } from 'config/theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 20px',
  },
  formTitle: {
    marginBottom: '20px',
  },
  formWrapper: {
    border: '0.5px solid #afafaf',
    borderRadius: '5px',
    padding: '35px',
    width: '100%',
  },
  textFieldLabel: {
    margin: '15px 0px',
  },
  textField: {
    marginBottom: '20px',
  },
  submit: {
    marginTop: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: '5px',
  },
}));

export default useStyles;
