import React from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';

import { Backdrop, CircularProgress } from 'components/base';
import { makeStyles } from 'config/theme';
import { getBackdropState, closeBackdrop } from 'reducers/backdropReducer';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1302,
    color: '#fff',
  },
}));

const CocoonBackdrop = ({ backdrop, handleCloseBackdrop }) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={backdrop.open}
      onClick={handleCloseBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

CocoonBackdrop.propTypes = {
  backdrop: object.isRequired,
  handleCloseBackdrop: func.isRequired,
};

const mapStateToProps = state => ({
  backdrop: getBackdropState(state),
});

const mapDispatchToProps = dispatch => ({
  handleCloseBackdrop: () => dispatch(closeBackdrop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CocoonBackdrop);
