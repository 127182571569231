import React from 'react';
import { func, shape, bool, string } from 'prop-types';
import { Form, Field } from 'formik';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import logoH from 'Images/logo/logo-h.svg';
import { openSnackbar } from 'reducers/snackbarReducer';
import {
  Box,
  Button,
  Typography,
  FormikTextField,
  Container,
  Alert,
} from 'components/base';
import { makeStyles } from 'config/theme';

// import { cookieUserId, isUserAdmin } from 'helpers/auth';
// import { useHistory } from 'react-router';
// import { LOGOUT } from 'apollo/mutations/auth-mutation';
// import { logOut } from 'config/auth/auth0';
// import ReduxAuth from 'config/redux/auth';
// import { useMutation } from 'react-apollo';

import GoogleSignIn from '../google/GoogleSignIn';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.text.secondary}`,
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '&::before': {
      marginRight: '1em',
    },
    '&::after': {
      marginLeft: '1em',
    },
  },
}));

const SignIn = ({
  handleSubmit,
  isSubmitting,
  dirty,
  errors,
  width,
  // handleOpenSnackbar,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const desktop = isWidthUp('sm', width);

  // const [logout] = useMutation(LOGOUT);

  // useEffect(() => {
  //   if (cookieUserId && !isUserAdmin) {
  //     logout();
  //     handleOpenSnackbar('error', 'You are not Admin!');
  //   }
  // }, []);

  return (
    <Container maxWidth="xs" component="main" className={classes.container}>
      <Box width={desktop ? 300 : 200}>
        <img src={logoH} alt="logo" />
      </Box>
      <Box my={desktop ? 3 : 1}>
        <Typography variant="h4">Admin Dashboard</Typography>
      </Box>
      <Form className={classes.form} onSubmit={handleSubmit}>
        {errors.general && (
          <Alert severity="error">
            <strong>{errors.general}</strong>
          </Alert>
        )}
        <Field
          component={FormikTextField}
          name="username"
          label="Username/Email"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          autoComplete="username"
          autoFocus
          size={desktop ? 'medium' : 'small'}
        />
        <Field
          component={FormikTextField}
          name="password"
          label="Password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="password"
          id="password"
          autoComplete="current-password"
          size={desktop ? 'medium' : 'small'}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting || !dirty}
        >
          <Typography variant="button" color="inherit">
            {t('sign.in.title')}
          </Typography>
        </Button>
      </Form>
      <Typography component="div" variant="button" align="center">
        <div className={classes.divider}>{t('common.or')}</div>
        <GoogleSignIn />
      </Typography>
    </Container>
  );
};

SignIn.propTypes = {
  handleSubmit: func.isRequired,
  // handleOpenSnackbar: func.isRequired,
  errors: shape({}).isRequired,
  isSubmitting: bool.isRequired,
  dirty: bool.isRequired,
  width: string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
});

export default connect(null, mapDispatchToProps)(withWidth()(SignIn));
