import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { users: [] };

// ACTION CREATORS
export const { initUsers, setUsers } = createActions({
  INIT_USERS: undefined,
  SET_USERS: users => ({ users }),
});

// REDUCERS
export default handleActions(
  {
    [initUsers]() {
      return { ...initState };
    },
    [setUsers](state, { payload: { users } }) {
      return { ...state, users };
    },
  },
  initState,
);

// SELECTORS
const selectorUser = state => state.user;

export const getUsers = createSelector([selectorUser], user => user.users);
