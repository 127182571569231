import gql from 'graphql-tag';

export const GET_CONTESTS = gql`
  query contests($page: Int!, $limit: PaginationLimit, $contestName: String) {
    contests(page: $page, limit: $limit, contestName: $contestName) {
      id
      name
      description
      isFeatured
      contestDeadline
      tags {
        id
        tagName
      }
      members {
        id
        email
      }
      projects {
        id
        name
      }
      creatorId
      creator {
        id
        email
      }
    }
  }
`;
