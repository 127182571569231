import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import People from '@material-ui/icons/People';

import { DashboardIcon, CategoryIcon } from 'components/base';

import Contests from '../screens/contests';
import Projects from '../screens/projects';
import Announcements from '../screens/announcements';
import Categories from '../screens/categories';
import Analysis from '../screens/analysis';
import Users from '../screens/users';
import Partners from '../screens/partners';

const routesMap = [
  {
    path: '/projects',
    component: Projects,
    name: 'Projects',
    icon: <DashboardIcon />,
  },
  {
    path: '/analysis',
    component: Analysis,
    name: 'Analysis',
    icon: <DataUsageIcon />,
    toggleKey: 'analysis',
  },
  {
    path: '/contests',
    component: Contests,
    name: 'Contests',
    icon: <FitnessCenterIcon />,
  },
  {
    path: '/categories',
    component: Categories,
    name: 'Categories',
    icon: <CategoryIcon />,
  },
  {
    path: '/users',
    component: Users,
    name: 'Users',
    icon: <PersonIcon />,
  },
  {
    path: '/partners',
    component: Partners,
    name: 'Partners',
    icon: <People />,
  },
  {
    path: '/announcements',
    component: Announcements,
    name: 'Announcements',
    icon: <AnnouncementIcon />,
  },
];

export default routesMap;
