/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { func, bool, number, string } from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from 'config/theme';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  // MenuIcon,
  ExitToAppIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'components/base';
import { LightTooltip } from 'components';

const useStyles = drawerWidth =>
  makeStyles(theme => ({
    toolbar: {
      paddingRight: 24,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      width: `calc(100% - ${theme.spacing(9)}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
  }));

const DashboardAppbar = ({
  drawerWidth,
  open,
  title,
  handleToggleDrawer,
  handleOpenLogoutDialog,
}) => {
  const classes = useStyles(drawerWidth)();
  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleToggleDrawer}
          className={classes.menuButton}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title}
        </Typography>
        <LightTooltip title="Log out">
          <IconButton color="inherit" onClick={handleOpenLogoutDialog}>
            <ExitToAppIcon />
          </IconButton>
        </LightTooltip>
      </Toolbar>
    </AppBar>
  );
};

DashboardAppbar.propTypes = {
  drawerWidth: number.isRequired,
  title: string.isRequired,
  open: bool.isRequired,
  handleToggleDrawer: func.isRequired,
  handleOpenLogoutDialog: func.isRequired,
};

export default DashboardAppbar;
