import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($idToken: String!) {
    login(idToken: $idToken) {
      id
      roleId
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      message
    }
  }
`;
