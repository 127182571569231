import React from 'react';
import { arrayOf, object, bool, func } from 'prop-types';
import { useMutation } from 'react-apollo';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';

import { makeStyles } from 'config/theme';
import { Checkbox, Box } from 'components';
import {
  CREATE_IAC_ANNOUNCEMENT,
  DELETE_IAC_ANNOUNCEMENT,
  EDIT_IAC_ANNOUNCEMENT,
} from 'apollo/mutations/announcement-mutation';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      backgroundColor: '#01579b',
      color: theme.palette.common.white,
      fontWeight: 700,
      textAlign: 'center',

      '& *, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white,
      },
    },
    '& .Component-horizontalScrollContainer-32': {
      overflow: 'visible !important',
    },
  },
}));

const Announcements = ({
  announcements,
  loading,
  refetch,
  handleOpenSnackbar,
}) => {
  const classes = useStyles();
  const [createAnnouncement] = useMutation(CREATE_IAC_ANNOUNCEMENT);
  const [editAnnouncement] = useMutation(EDIT_IAC_ANNOUNCEMENT);
  const [deleteAnnouncement] = useMutation(DELETE_IAC_ANNOUNCEMENT);

  const columns = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Text',
      field: 'text',
    },
    {
      title: 'Active',
      field: 'isActive',
      type: 'boolean',
      editable: 'onUpdate',
      editComponent: comp => {
        return (
          <Checkbox
            checked={comp.value}
            onChange={e => {
              comp.onChange(e.target.checked);
            }}
          />
        );
      },
    },
  ];

  const options = {
    sorting: true,
    filtering: true,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    showTitle: false,
    toolbarButtonAlignment: 'left',
    padding: 'dense',
    addRowPosition: 'first',
    headerStyle: {
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#384885',
    },
  };

  const editable = {
    onRowAdd: async ({ name, text }) => {
      try {
        await createAnnouncement({
          variables: { data: { name, text, isActive: false } },
        });

        handleOpenSnackbar(
          'success',
          `The announcement is created successfully!`,
        );

        refetch();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit);
      }
    },
    onRowUpdate: async (newData, oldData) => {
      if (
        newData.name !== oldData.name ||
        newData.text !== oldData.text ||
        newData.isActive !== oldData.isActive
      ) {
        const { id, name, text, isActive } = newData;

        try {
          await editAnnouncement({
            variables: { id, data: { name, text, isActive } },
          });

          handleOpenSnackbar(
            'success',
            `The announcement is edited successfully!`,
          );
          refetch();
        } catch (errorSubmit) {
          handleOpenSnackbar('error', errorSubmit);
        }
      }
    },
    onRowDelete: async ({ id }) => {
      try {
        await deleteAnnouncement({
          variables: { id: parseInt(id, 10) },
        });
        handleOpenSnackbar(
          'success',
          `The announcement is deleted successfully!`,
        );
        refetch();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit);
      }
    },
  };

  const actions = [
    {
      icon: () => <RefreshIcon />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
  ];

  return (
    <Box className={classes.table}>
      <MaterialTable
        data={announcements}
        title="Announcements"
        isLoading={loading}
        columns={columns}
        options={options}
        editable={editable}
        actions={actions}
      />
    </Box>
  );
};

Announcements.propTypes = {
  announcements: arrayOf(object).isRequired,
  loading: bool.isRequired,
  refetch: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

export default Announcements;
