import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      backgroundColor: '#01579b',
      color: theme.palette.common.white,
      fontWeight: 700,
      textAlign: 'center',

      '& *, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white,
      },

      '&:first-child': {
        padding: 4,
      },
    },
    '& td > div': {
      justifyContent: 'center',
    },
    '& .Component-horizontalScrollContainer-32': {
      overflow: 'visible !important',
    },
  },
  filterColumns: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '& > label': {
      marginLeft: 0,
    },
  },

  select: {
    width: '100%',
    textAlign: 'left',
  },
}));

export default useStyles;
