import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { useLazyQuery } from 'react-apollo';

import { getCurrentUser, updateCurrentUser } from 'reducers/authReducer';
import { GET_USER } from 'apollo/queries/user-query';
import { cookieUserId } from 'helpers/auth';

import AppLayout from './AppLayout';

const AppLayoutContainer = ({
  currentUser,
  handleUpdateCurrentUser,
  ...props
}) => {
  const [geCurrenttUserCb, { data: dataUser }] = useLazyQuery(GET_USER, {
    variables: { id: cookieUserId },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleUpdateCurrentUser(dataUser.userById);
    },
  });

  useEffect(() => {
    if (cookieUserId && currentUser.id === undefined) {
      geCurrenttUserCb();
    }
  }, []);

  return <AppLayout {...props} />;
};

AppLayoutContainer.propTypes = {
  currentUser: object.isRequired,
  handleUpdateCurrentUser: func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
  handleUpdateCurrentUser: user => dispatch(updateCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayoutContainer);

export { default as AppFooter } from './components/footer';
export { default as AppHeader } from './components/header';
