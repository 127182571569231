import React from 'react';
import { arrayOf, object, bool, func } from 'prop-types';
import { useMutation } from 'react-apollo';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';

import { makeStyles } from 'config/theme';
import { Box } from 'components';
import {
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
} from 'apollo/mutations/project-category-mutation';

import InputColor from './components/InputColor';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      backgroundColor: '#01579b',
      color: theme.palette.common.white,
      fontWeight: 700,
      textAlign: 'center',

      '& *, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white,
      },
    },
    '& .Component-horizontalScrollContainer-32': {
      overflow: 'visible !important',
    },
  },
}));

const Categories = ({ categories, loading, refetch, handleOpenSnackbar }) => {
  const classes = useStyles();

  const [createCategory] = useMutation(CREATE_CATEGORY);
  const [editCategory] = useMutation(EDIT_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);

  const columns = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
    },
    { title: 'Name', field: 'name' },
    {
      title: 'Display Color',
      field: 'displayColor',
      render: rowData => <InputColor color={rowData.displayColor} />,
      editComponent: comp => (
        <InputColor
          color={comp.value || '#fff'}
          handleChangeColor={newColor => comp.onChange(newColor.hex)}
        />
      ),
    },
  ];

  const options = {
    sorting: true,
    filtering: true,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    showTitle: false,
    toolbarButtonAlignment: 'left',
    padding: 'dense',
    addRowPosition: 'first',
    headerStyle: {
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#384885',
    },
  };

  const editable = {
    onRowAdd: async ({ name, displayColor }) => {
      try {
        await createCategory({
          variables: { name, displayColor: displayColor || '#fff' },
        });
        handleOpenSnackbar(
          'success',
          `The category ${name} is created successfully!`,
        );
        refetch();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit);
      }
    },
    onRowUpdate: async (newData, oldData) => {
      if (
        newData.name !== oldData.name ||
        newData.displayColor !== oldData.displayColor
      ) {
        const { id, name, displayColor } = newData;
        try {
          await editCategory({
            variables: { id, name, displayColor },
          });
          handleOpenSnackbar(
            'success',
            `The category ${name} is edited successfully!`,
          );
          refetch();
        } catch (errorSubmit) {
          handleOpenSnackbar('error', errorSubmit);
        }
      }
    },
    onRowDelete: async ({ id, name }) => {
      try {
        await deleteCategory({
          variables: { id },
        });
        handleOpenSnackbar(
          'success',
          `The category ${name} is deleted successfully!`,
        );
        refetch();
      } catch (errorSubmit) {
        handleOpenSnackbar('error', errorSubmit);
      }
    },
  };

  const actions = [
    {
      icon: () => <RefreshIcon />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
  ];

  return (
    <Box className={classes.table}>
      <MaterialTable
        data={categories}
        title="Categories"
        isLoading={loading}
        columns={columns}
        options={options}
        editable={editable}
        actions={actions}
      />
    </Box>
  );
};

Categories.propTypes = {
  loading: bool.isRequired,
  categories: arrayOf(object).isRequired,
  refetch: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

export default Categories;
