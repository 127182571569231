import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  open: false,
  type: 'info',
  content: '',
};

// ACTION CREATORS
export const { openSnackbar, closeSnackbar } = createActions({
  OPEN_SNACKBAR: (type, content) => ({ type, content }),
  CLOSE_SNACKBAR: undefined,
});

// REDUCERS
export default handleActions(
  {
    [openSnackbar](state, { payload: { type, content } }) {
      return { ...state, open: true, type, content };
    },
    [closeSnackbar](state) {
      return { ...state, open: false };
    },
  },
  initState,
);

// SELECTORS
const selectorSnackbar = state => state.snackbar;

export const getSnackbarState = createSelector(
  [selectorSnackbar],
  snackbar => snackbar,
);
