import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import Favicon from 'react-favicon';

import 'config/common/i18n';
// import 'config/common/sentry';
import configureStore from 'config/redux/store';
import log from 'helpers/log';
import routes from 'helpers/routes';

import favicon from '../dist/favicon.svg';

import App from './App';

const store = configureStore();

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    fetchOptions: {
      credentials: 'include',
    },
  }));
  return forward(operation);
});

const client = new ApolloClient({
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          log.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) log.error(`[Network error]: ${networkError}`);
    }),
    authMiddleware,
    new HttpLink({ uri: process.env.GRAPHQL_ENDPOINT }),
  ]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <ApolloProvider client={client}>
        <Router basename="/" forceRefresh={false}>
          <Favicon url={favicon} />
          <Route path={routes.map(route => route.path)}>
            <App routes={routes} />
          </Route>
        </Router>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('cocoon-app'),
);
