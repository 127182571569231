import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  open: false,
};

// ACTION CREATORS
export const { openBackdrop, closeBackdrop } = createActions({
  OPEN_BACKDROP: undefined,
  CLOSE_BACKDROP: undefined,
});

// REDUCERS
export default handleActions(
  {
    [openBackdrop](state) {
      return { ...state, open: true };
    },
    [closeBackdrop](state) {
      return { ...state, open: false };
    },
  },
  initState,
);

// SELECTORS
const selectorBackdrop = state => state.backdrop;

export const getBackdropState = createSelector(
  [selectorBackdrop],
  backdrop => backdrop,
);
