import React from 'react';
import { element } from 'prop-types';

import {
  useScrollTrigger,
  Zoom,
  Toolbar,
  Fab,
  KeyboardArrowUpIcon,
} from 'components/base';
import { withStyles, makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export const backToAnchor = node => {
  const anchor = node.querySelector('#back-to-top-anchor');

  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const BackTop = props => {
  const { children } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    backToAnchor(event.target.ownerDocument || document);
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

BackTop.propTypes = {
  children: element.isRequired,
};

const CocoonBackTop = () => (
  <BackTop>
    <Fab color="primary" size="small" aria-label="scroll back to top">
      <KeyboardArrowUpIcon />
    </Fab>
  </BackTop>
);

export const BackToTopAnchor = withStyles({
  root: {
    minHeight: 0,
  },
})(props => <Toolbar id="back-to-top-anchor" variant="dense" {...props} />);

export default CocoonBackTop;
