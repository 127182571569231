import gql from 'graphql-tag';

export const ADD_SPONSORS_PROJECT = gql`
  mutation createSponsors($projectId: ID!, $usersIds: [ID!]!) {
    createSponsors(projectId: $projectId, usersIds: $usersIds) {
      status
      description
    }
  }
`;

export const DELETE_SPONSORS_PROJECT = gql`
  mutation deleteSponsors($projectId: ID!, $usersIds: [ID!]!) {
    deleteSponsors(projectId: $projectId, usersIds: $userIds) {
      status
      description
    }
  }
`;
