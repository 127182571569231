import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import defaultUser from 'Images/default-user.png';

// INITIAL STATE
const initState = { currentUser: { avatarLink: defaultUser } };

// ACTION CREATORS
export const { updateCurrentUser } = createActions({
  UPDATE_CURRENT_USER: (currentUser, isNew = false) => ({ currentUser, isNew }),
});

// REDUCERS
export default handleActions(
  {
    [updateCurrentUser](state, { payload: { currentUser, isNew } }) {
      delete currentUser.passwordConfirmation;
      return isNew ? { ...initState, currentUser } : { ...state, currentUser };
    },
  },
  initState,
);

// SELECTORS

// SELECTORS
const selectorAuth = state => state.auth;
const selectorCurrentUser = state => state.auth.currentUser;

export const getAuth = createSelector([selectorAuth], auth => auth);
export const getCurrentUser = createSelector(
  [selectorCurrentUser],
  currentUser => currentUser,
);
export const getCurrentUserAvatarLink = createSelector(
  [selectorCurrentUser],
  currentUser => currentUser.avatarLink,
);
