import React, { useEffect, useState } from 'react';
import { arrayOf, func, object, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useLazyQuery } from 'react-apollo';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { openDialog } from 'reducers/dialogReducer';
import { DIALOG } from 'helpers/constants';
import { setLocalStorage, getLocalStorage, KEYS } from 'helpers/localStorage';
import { GET_IAC_PROJECT_CATEGORIES } from 'apollo/queries/project-category-query';
import { GET_PROJECTS } from 'apollo/queries/project-query';
import { GET_CONTESTS } from 'apollo/queries/contests-query';
import { GET_USERS } from 'apollo/queries/user-query';
import { GET_PARTNERS } from 'apollo/queries/partner-query';
import { GET_IAC_ANNOUNCEMENTS } from 'apollo/queries/announcement-query';
import { getCategories, setCategories } from 'reducers/categoryReducer';
import {
  getAnnouncements,
  setAnnouncements,
} from 'reducers/announcementReducer';
import { getProjects, setProjects } from 'reducers/projectReducer';
import { getContests, setContests } from 'reducers/contestReducer';
import { getUsers, setUsers } from 'reducers/userReducer';
import { getPartners, setPartners } from 'reducers/partnerReducer';
import { getViewCount, setViewCount } from 'reducers/viewCountReducer';
import { Alert, CircularCenterLoader } from 'components';
import { getToggle } from 'reducers/toggleReducer';

import Dashboard from './Dashboard';
import routesMap from './helpers/dashboardRoutes';

const DashboardContainer = ({
  categories,
  projects,
  contests,
  users,
  partners,
  announcements,
  handleSetCategories,
  handleSetProjects,
  handleSetContests,
  handleSetUsers,
  handleSetPartners,
  handleSetAnnouncements,
  handleOpenDialog,
  width,
  toggle,
}) => {
  const savedToggle = getLocalStorage(KEYS.adminCocoonDrawer);
  const desktop = isWidthUp('sm', width);
  const location = useLocation();
  const [open, setOpen] = useState(
    // eslint-disable-next-line no-nested-ternary
    !desktop ? false : savedToggle ? savedToggle === 'true' : true,
  );
  const [routesDashboard, setRoutesDashboard] = useState(routesMap);
  const [selectedRoute, setSelectedRoute] = useState(
    _.find(routesDashboard, ['path', location.pathname]) || routesDashboard[0],
  );
  const history = useHistory();

  // Filter toggle with route map
  useEffect(() => {
    let filterRoutes = _.filter(routesMap, route => {
      const toggleKey = route?.toggleKey;
      if (toggleKey) {
        return toggle[toggleKey];
      } else {
        return true;
      }
    });
    setRoutesDashboard(filterRoutes);
    setSelectedRoute(
      _.find(filterRoutes, ['path', location.pathname]) || filterRoutes[0],
    );
  }, [toggle]);

  const handleToggleDrawer = () => {
    setOpen(!open);
    setLocalStorage(KEYS.adminCocoonDrawer, !open);
  };

  const handleListItemClick = (event, route) => {
    setSelectedRoute(route);
    history.push(route.path);
    if (!desktop && open) {
      handleToggleDrawer();
    }
  };

  const handleOpenLogoutDialog = e => {
    e.preventDefault();
    handleOpenDialog(DIALOG.LOGOUT);
  };

  // Categories
  const [
    getCategoriesCb,
    {
      data: dataCategories,
      loading: loadingCategories,
      error: errorCategories,
    },
  ] = useLazyQuery(GET_IAC_PROJECT_CATEGORIES, {
    onCompleted: () => {
      handleSetCategories(dataCategories.projectCategories);
    },
    variables: { page: 1, limit: 'FIVE_O' },
  });

  // Projects
  const [
    getProjectsCb,
    { data: dataProjects, loading: loadingProjects, error: errorProjects },
  ] = useLazyQuery(GET_PROJECTS, {
    variables: {
      page: 1,
      limit: 'FIVE_O',
    },
    onCompleted: () => {
      handleSetProjects(dataProjects.projects);
    },
  });

  // Contests
  const [
    getContestsCb,
    { data: dataContests, loading: loadingContests, error: errorContests },
  ] = useLazyQuery(GET_CONTESTS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleSetContests(dataContests.contests);
    },
    variables: { page: 1, limit: 'FIVE_O', contestName: '' },
  });

  // Users
  const [
    getUsersCb,
    { data: dataUsers, loading: loadingUsers, error: errorUsers },
  ] = useLazyQuery(GET_USERS, {
    variables: { page: 1, limit: 'FIVE_O' },
    onCompleted: () => {
      handleSetUsers(dataUsers.users);
    },
  });

  // Partners
  const [
    getPartnersCb,
    { data: dataPartners, loading: loadingPartners, error: errorPartners },
  ] = useLazyQuery(GET_PARTNERS, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleSetPartners(dataPartners.partners);
    },
    variables: { page: 1, limit: 'FIVE_O' },
  });

  // Announcements
  const [
    getAnnouncementsCb,
    {
      data: dataAnnouncements,
      loading: loadingAnnouncements,
      error: errorAnnouncements,
    },
  ] = useLazyQuery(GET_IAC_ANNOUNCEMENTS, {
    variables: { page: 1, limit: 'FIVE_O' },
    onCompleted: () => {
      handleSetAnnouncements(dataAnnouncements.announcements);
    },
  });

  // Get inital data of all component
  useEffect(() => {
    if (_.isEmpty(categories)) {
      getCategoriesCb();
    }
    if (_.isEmpty(projects)) {
      getProjectsCb();
    }
    if (_.isEmpty(contests)) {
      getContestsCb();
    }
    if (_.isEmpty(users)) {
      getUsersCb();
    }
    if (_.isEmpty(partners)) {
      getPartnersCb();
    }
    if (_.isEmpty(announcements)) {
      getAnnouncementsCb();
    }
  }, []);
  if (
    loadingCategories ||
    loadingProjects ||
    loadingContests ||
    loadingUsers ||
    loadingPartners ||
    loadingAnnouncements
  )
    return <CircularCenterLoader height={380} />;

  if (
    errorCategories ||
    errorProjects ||
    errorContests ||
    errorUsers ||
    errorPartners ||
    errorAnnouncements
  ) {
    return (
      <Alert variant="outlined" severity="error">
        {errorCategories && errorCategories.message}
        {errorProjects && errorProjects.message}
        {errorContests && errorContests.message}
        {errorUsers && errorUsers.message}
        {errorPartners && errorPartners.message}
        {errorAnnouncements && errorAnnouncements.message}
      </Alert>
    );
  }

  return (
    <Dashboard
      open={open}
      routesMap={routesDashboard}
      selectedRoute={selectedRoute}
      handleListItemClick={handleListItemClick}
      handleToggleDrawer={handleToggleDrawer}
      handleOpenLogoutDialog={handleOpenLogoutDialog}
    />
  );
};

DashboardContainer.propTypes = {
  categories: arrayOf(object).isRequired,
  projects: arrayOf(object).isRequired,
  contests: arrayOf(object).isRequired,
  users: arrayOf(object).isRequired,
  partners: arrayOf(object).isRequired,
  announcements: arrayOf(object).isRequired,
  handleOpenDialog: func.isRequired,
  handleSetProjects: func.isRequired,
  handleSetContests: func.isRequired,
  handleSetCategories: func.isRequired,
  handleSetUsers: func.isRequired,
  handleSetPartners: func.isRequired,
  handleSetAnnouncements: func.isRequired,
  width: string.isRequired,
};

const mapStateToProps = state => ({
  categories: getCategories(state),
  projects: getProjects(state),
  contests: getContests(state),
  users: getUsers(state),
  partners: getPartners(state),
  announcements: getAnnouncements(state),
  toggle: getToggle(state),
});

const mapDispatchToProps = dispatch => ({
  handleSetCategories: categories => dispatch(setCategories(categories)),
  handleSetProjects: projects => {
    dispatch(setProjects(projects));
  },
  handleSetContests: contests => dispatch(setContests(contests)),
  handleSetUsers: users => dispatch(setUsers(users)),
  handleSetPartners: partners => dispatch(setPartners(partners)),
  handleSetAnnouncements: announcements =>
    dispatch(setAnnouncements(announcements)),
  handleOpenDialog: (type, options) => dispatch(openDialog(type, options)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(DashboardContainer));
