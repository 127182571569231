import React, { useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { arrayOf, object, func } from 'prop-types';

import { Alert } from 'components';
import { openSnackbar } from 'reducers/snackbarReducer';
import { getPartners, setPartners } from 'reducers/partnerReducer';
import { openBackdrop, closeBackdrop } from 'reducers/backdropReducer';
import { GET_PARTNERS } from 'apollo/queries/partner-query';
import { UPDATE_USER } from 'apollo/mutations/user-mutation';

import Partners from './Partners';

const PartnersContainer = ({
  partners,
  handleSetPartners,
  handleOpenSnackbar,
  handleOpenBackdrop,
  handleCloseBackdrop,
}) => {
  const [showForm, setShowForm] = useState(false);

  const [getPartnersCb, { data: cbData, loading, error }] = useLazyQuery(
    GET_PARTNERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        handleSetPartners(cbData.partners);
      },
      variables: { page: 1, limit: 'FIVE_O' },
    },
  );

  const [updateUserMutation] = useMutation(UPDATE_USER);

  const updateUser = async (userId, email, partnerName, description) => {
    try {
      await updateUserMutation({
        variables: {
          data: {
            id: userId,
            partnerName: partnerName || '',
            description: description || '',
          },
        },
      });
      handleOpenSnackbar('success', `Partner ${email} updated successfully!`);
      getPartnersCb();
    } catch (errorUpdateUser) {
      handleOpenSnackbar('error', errorUpdateUser.message);
    }
  };

  if (error)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  return (
    <Partners
      partners={partners}
      loading={loading}
      showForm={showForm}
      setShowForm={setShowForm}
      handleOpenSnackbar={handleOpenSnackbar}
      handleOpenBackdrop={handleOpenBackdrop}
      handleCloseBackdrop={handleCloseBackdrop}
      refetch={getPartnersCb}
      updateUser={updateUser}
    />
  );
};

PartnersContainer.propTypes = {
  partners: arrayOf(object).isRequired,
  handleSetPartners: func.isRequired,
  handleOpenSnackbar: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
};

const mapStateToProps = state => ({
  partners: getPartners(state),
});

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleOpenBackdrop: () => dispatch(openBackdrop()),
  handleCloseBackdrop: () => dispatch(closeBackdrop()),
  handleSetPartners: partners => dispatch(setPartners(partners)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersContainer);
