import React from 'react';
import { string, func } from 'prop-types';

import { ButtonColorPicker, Box } from 'components';

const Page = ({ data, url }) => {
  return (
    data.type && (
      <>
        <a href={url}>{data.type + '/' + data.name}</a>
      </>
    )
  );
};

Page.propTypes = {
  data: string,
};

Page.defaultProps = {
  data: undefined,
};

export default Page;
