import React from 'react';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { useMutation } from 'react-apollo';
import { useHistory, useLocation } from 'react-router';

import { makeStyles } from 'config/theme';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  LockOpenIcon,
  Grid,
} from 'components/base';
import { closeDialog } from 'reducers/dialogReducer';
import { openSnackbar } from 'reducers/snackbarReducer';
import { useUnload } from 'hooks';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  },
  text: {
    color: '#575757',
  },
}));

const DeleteDialog = ({
  open,
  handleCloseDialog,
  handleOpenSnackbar,
  options,
}) => {
  // Prevent user reload when dialog is opened
  useUnload(handleCloseDialog);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { itemName, variables, mutation, resultObjectName } = options;
  const [mutationPromise] = useMutation(mutation);

  const handleDeleteItem = async e => {
    e.preventDefault();

    try {
      const { data } = await mutationPromise({
        variables,
      });

      if (data) {
        handleCloseDialog();
        handleOpenSnackbar(
          'success',
          `The log ${data[resultObjectName].name} is deleted successfully!`,
        );
        history.push({
          pathname: location.pathname,
          state: { isRefetch: true },
        });
      }
    } catch (error) {
      handleOpenSnackbar('error', error.message);
    }
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="delete-dialog-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant="h5">
              <Box
                display="flex"
                alignItems="center"
                className={classes.header}
              >
                <LockOpenIcon color="primary" className={classes.icon} />
                Deleting this {itemName}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs>
            <DialogActions>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={handleCloseDialog}
              >
                Back
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleDeleteItem}
              >
                Confirm & Delete
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          component={Typography}
          my={2}
          variant="body1"
          className={classes.text}
        >
          This {itemName} will be permanently deleted from Tree Preview and
          Project Cover. Note that you can always unpublish a log by changing
          its publishing status.
        </Box>
        <Box
          component={Typography}
          my={2}
          variant="body1"
          className={classes.text}
        >
          Do you wish to continue deleting this {itemName}?
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

DeleteDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleCloseDialog: () => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(DeleteDialog);
