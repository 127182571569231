import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { arrayOf, object, func } from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useMutation, useLazyQuery } from 'react-apollo';
import { useHistory } from 'react-router';

import { cookieUserId, isUserAdmin } from 'helpers/auth';
import { CocoonSnackbar, CocoonDialog, CocoonBackdrop } from 'components';
import { withStyles, theme, ThemeProvider } from 'config/theme';
import { LOGOUT } from 'apollo/mutations/auth-mutation';
import { GET_TOGGLE } from 'apollo/queries/toggle-query';
import { getToggle, setToggle } from 'reducers/toggleReducer';

import ReduxAuth from './config/redux/auth';
import { logOut } from './config/auth/auth0';
import CocoonBackTop, { BackToTopAnchor } from './components/back-top/BackTop';
import NotFoundErrorPage from './screens/error/NotFoundErrorPage';
import { openSnackbar } from './reducers/snackbarReducer';

const GlobalCss = withStyles({
  '@global': {
    '*': {
      margin: 0,
    },
    'body, html, #cocoon-app': {
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
  },
})(() => null);

const App = ({ routes, handleOpenSnackbar, toggle, handleSetToggle }) => {
  const [logout] = useMutation(LOGOUT);
  const history = useHistory();

  const handleLogout = async () => {
    await logout();
    await ReduxAuth.logout(history);
    logOut();
  };

  // Get Toggle
  const [
    getToggleCb,
    { data: dataToggle, loading: loadingToggle, error: errorToggle },
  ] = useLazyQuery(GET_TOGGLE, {
    onCompleted: () => {
      handleSetToggle(dataToggle.toggle);
    },
  });

  useEffect(() => {
    getToggleCb();
    // 2 hours to expire, regardless of activity
    const expireTime = 1000 * 60 * 60 * 2;
    if (history.location.pathname !== '/signin') {
      setTimeout(() => {
        handleOpenSnackbar('info', 'Session timed out. Please sign in again.');
        handleLogout();
      }, expireTime);
    }
    if (cookieUserId && !isUserAdmin) {
      logout();
      handleOpenSnackbar('error', 'You are not Admin!');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalCss />
      <BackToTopAnchor />
      <Switch>
        {routes.map(({ path, component, isPublic }, key) => {
          if (isPublic) {
            return <Route key={key} path={path} component={component} />;
          }
          if (cookieUserId && isUserAdmin) {
            return <Route key={key} path={path} component={component} />;
          }
          return <Redirect key={key} to="/signin" exact />;
        })}
        <Route component={NotFoundErrorPage} />
      </Switch>
      <CocoonBackTop />
      <CocoonSnackbar />
      <CocoonBackdrop />
      <CocoonDialog />
    </ThemeProvider>
  );
};

App.propTypes = {
  routes: arrayOf(object).isRequired,
  handleOpenSnackbar: func.isRequired,
  handleSetToggle: func.isRequired,
};

const mapStateToProps = state => ({
  toggle: getToggle(state),
});

const mapDispatchToProps = dispatch => ({
  handleSetToggle: toggle => dispatch(setToggle(toggle)),
  handleOpenSnackbar: openSnackbar,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
