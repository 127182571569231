import { useCallback, useEffect, useRef, useMemo } from 'react';
import _ from 'lodash';

const createCallback = (debounce, handleOnScroll, options) => {
  if (debounce) {
    return _.debounce(handleOnScroll, debounce, options);
  }
  return handleOnScroll;
};

const useBottomScrollListener = (
  onBottom,
  offset = 300,
  debounce = 700,
  debounceOptions = { leading: true },
) => {
  const debouncedOnBottom = useMemo(
    () => createCallback(debounce, onBottom, debounceOptions),
    [debounce, onBottom],
  );
  const containerRef = useRef(null);
  const handleOnScroll = useCallback(() => {
    if (containerRef.current != null) {
      const scrollNode = containerRef.current;
      const scrollContainerBottomPosition = Math.round(
        scrollNode.scrollTop + scrollNode.clientHeight,
      );
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) {
        debouncedOnBottom();
      }
    } else {
      const scrollNode = document.scrollingElement || document.documentElement;
      const scrollContainerBottomPosition = Math.round(
        scrollNode.scrollTop + window.innerHeight,
      );
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) {
        debouncedOnBottom();
      }
    }
    // ref dependency needed for the tests, doesn't matter for normal execution
  }, [offset, onBottom, containerRef.current]);

  useEffect(() => {
    const ref = containerRef.current;
    if (ref != null) {
      ref.addEventListener('scroll', handleOnScroll);
    } else {
      window.addEventListener('scroll', handleOnScroll);
    }

    return () => {
      if (ref != null) {
        ref.removeEventListener('scroll', handleOnScroll);
      } else {
        window.removeEventListener('scroll', handleOnScroll);
      }
    };
  }, [handleOnScroll, debounce]);

  return containerRef;
};

export default useBottomScrollListener;
