// this hook listens if window is active or not
import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

const useWindowIsFocused = () => {
  const [windowIsActive, setWindowIsActive] = useState(true);

  const handleActivity = useCallback(
    _.debounce(
      e => {
        if (e.type === 'focus') {
          return setWindowIsActive(true);
        }
        if (e.type === 'blur') {
          return setWindowIsActive(false);
        }
        if (e.type === 'visibilitychange') {
          if (document.hidden) {
            return setWindowIsActive(false);
          }
          return setWindowIsActive(true);
        }

        return setWindowIsActive(true);
      },
      200,
      { leading: false },
    ),
    [],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', handleActivity);
    document.addEventListener('blur', handleActivity);
    window.addEventListener('blur', handleActivity);
    document.addEventListener('focus', handleActivity);
    window.addEventListener('focus', handleActivity);

    return () => {
      document.removeEventListener('visibilitychange', handleActivity);
      document.removeEventListener('blur', handleActivity);
      window.removeEventListener('blur', handleActivity);
      document.removeEventListener('focus', handleActivity);
      window.removeEventListener('focus', handleActivity);
    };
  }, []);

  return [windowIsActive];
};

export default useWindowIsFocused;
