import { droneRanger, brandonText, brandonTextBold } from './fonts';

const color = '#333';

const typography = {
  typography: {
    fontFamily: [
      'Brandon Text',
      'Drone Ranger',
      'Montserrat',
      'sans-serif',
    ].join(','),
    h2: {
      fontFamily: 'Drone Ranger',
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: '60px',
      letterSpacing: '-1px',
      color,
    },
    h4: {
      fontFamily: 'Brandon Text',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '35px',
      color,
    },
    h5: {
      fontFamily: 'Brandon Text',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color,
    },
    h6: {
      fontFamily: 'Brandon Text',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color,
    },
    button: {
      fontFamily: 'Drone Ranger',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      textTransform: 'none',
      color,
    },
    body1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '20px',
      color,
    },
    caption: {
      fontFamily: 'Brandon Text',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '20px',
      color,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [brandonText, brandonTextBold, droneRanger],
      },
    },
    MuiTooltip: {
      tooltip: {
        border: `2px solid #384885`,
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: '1px 1px 3px 0px rgb(0,0,0)',
        fontSize: 12,
      },
    },
  },
};

export default typography;
