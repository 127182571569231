import React from 'react';
import { string, func } from 'prop-types';

import { ButtonColorPicker, Box } from 'components';

const InputColor = ({ color, handleChangeColor }) => (
  <Box display="flex" alignItems="center" justifyContent="flex-start">
    <ButtonColorPicker color={color} handleChangeColor={handleChangeColor} />
    <Box ml={2}>{color}</Box>
  </Box>
);

InputColor.propTypes = {
  color: string,
  handleChangeColor: func,
};

InputColor.defaultProps = {
  color: undefined,
  handleChangeColor: undefined,
};

export default InputColor;
