import React, { useState } from 'react';
import { string } from 'prop-types';

import { makeStyles } from 'config/theme';
import {
  Truncate,
  Popover,
  Typography,
  Link,
  LightTooltip,
  Switch,
  FormControlLabel,
  Box,
  CocoonEditor,
} from 'components';

const useStyles = makeStyles(theme => ({
  link: {
    color: '#000',
    cursor: 'pointer',
  },
  popover: {
    width: 400,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& label': {
      marginBottom: theme.spacing(1),
      marginLeft: 0,
    },
  },
}));

const RichTextLink = ({ text }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rendered, setRendered] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleRendered = () => {
    setRendered(!rendered);
  };

  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <LightTooltip title="Click to view full">
        <Link
          aria-describedby={id}
          className={classes.link}
          onClick={handleClick}
        >
          <Truncate lines={2} width={200}>
            {text}
          </Truncate>
        </Link>
      </LightTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popover}>
          <FormControlLabel
            control={
              <Switch
                checked={rendered}
                size="small"
                onChange={handleToggleRendered}
              />
            }
            label="Rendered"
          />
          {rendered ? (
            <CocoonEditor data={text} />
          ) : (
            <Typography variant="caption">{text}</Typography>
          )}
        </Box>
      </Popover>
    </div>
  );
};

RichTextLink.propTypes = {
  text: string.isRequired,
};

export default RichTextLink;
