import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { arrayOf, func, object } from 'prop-types';
import { connect } from 'react-redux';

import { GET_IAC_PROJECT_CATEGORIES } from 'apollo/queries/project-category-query';
import { openSnackbar } from 'reducers/snackbarReducer';
import { Alert } from 'components';
import { getCategories, setCategories } from 'reducers/categoryReducer';

import Categories from './Categories';

const CategoriesContainer = ({
  handleOpenSnackbar,
  handleSetCategories,
  categories,
}) => {
  const [getCategoriesCb, { data, loading, error }] = useLazyQuery(
    GET_IAC_PROJECT_CATEGORIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        handleSetCategories(data.projectCategories);
      },
      variables: { page: 1, limit: 'FIVE_O' },
    },
  );

  if (error)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  return (
    <Categories
      categories={categories}
      loading={loading}
      refetch={getCategoriesCb}
      handleOpenSnackbar={handleOpenSnackbar}
    />
  );
};

CategoriesContainer.propTypes = {
  handleOpenSnackbar: func.isRequired,
  handleSetCategories: func.isRequired,
  categories: arrayOf(object).isRequired,
};

const mapStateToProps = state => ({
  categories: getCategories(state),
});

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleSetCategories: categories => dispatch(setCategories(categories)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesContainer);
