// i18n.js
// I18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// loading translation files from external location
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from '../../../assets/locales';

export const defaultLocale = localStorage.getItem('cocoon-lang')
  ? localStorage.getItem('cocoon-lang')
  : 'en';

export const locales = [
  {
    key: 'en',
    value: 'en',
    text: 'English',
  },
  {
    key: 'vi',
    value: 'vi',
    text: 'Tiếng Việt',
  },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,

    // all namespaces needed to fetch -> separate file to load
    ns: ['common', 'glossary', 'validation'],

    // default namespace
    defaultNS: 'common',

    // Dynamically fetching user language
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    lng: defaultLocale,

    // use en if detected language is not available
    fallbackLng: defaultLocale,

    // debug mode for development
    debug: false,

    interpolation: {
      escapeValue: false,
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
  });

export default i18n;
