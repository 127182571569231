import gql from 'graphql-tag';

export const GET_PARTNERS = gql`
  query partners($page: Int!, $limit: PaginationLimit) {
    partners(page: $page, limit: $limit) {
      id
      partnerName
      username
      email
      createdAt
      updatedAt
      roleId
      partnerName
      description
      fullBio
      fieldsOfWork
      avatarLink
      github
      linkedin
      facebook
      twitter
      # TODO: implement later
      # tags {
      #   id
      #   tagName
      # }
      # members {
      #   id
      #   email
      # }
    }
  }
`;
