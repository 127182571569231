import React from 'react';
import { useLazyQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { arrayOf, func, object } from 'prop-types';

import { Alert } from 'components';
import { openSnackbar } from 'reducers/snackbarReducer';
import { GET_IAC_ANNOUNCEMENTS } from 'apollo/queries/announcement-query';
import {
  getAnnouncements,
  setAnnouncements,
} from 'reducers/announcementReducer';

import Announcements from './Announcements';

const AnnouncementContainer = ({
  handleOpenSnackbar,
  handleSetAnnouncements,
  announcements,
}) => {
  const [getAnnouncementsCb, { data, loading, error }] = useLazyQuery(
    GET_IAC_ANNOUNCEMENTS,
    {
      variables: { page: 1, limit: 'FIVE_O' },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        handleSetAnnouncements(data.announcements);
      },
    },
  );

  if (error)
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  return (
    <Announcements
      announcements={announcements}
      loading={loading}
      refetch={getAnnouncementsCb}
      handleOpenSnackbar={handleOpenSnackbar}
    />
  );
};

AnnouncementContainer.propTypes = {
  handleOpenSnackbar: func.isRequired,
  handleSetAnnouncements: func.isRequired,
  announcements: arrayOf(object).isRequired,
};

const mapStateToProps = state => ({
  announcements: getAnnouncements(state),
});

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleSetAnnouncements: announcements =>
    dispatch(setAnnouncements(announcements)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnouncementContainer);
