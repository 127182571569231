import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { toggle: {} };

export const { initToggle, setToggle } = createActions({
  INIT_TOGGLE: undefined,
  SET_TOGGLE: toggle => ({ toggle }),
});

// REDUCERS
export default handleActions(
  {
    [initToggle]() {
      return { ...initState };
    },
    [setToggle](state, { payload: { toggle } }) {
      return { ...state, toggle };
    },
  },
  initState,
);

// SELECTORS
const selectorToggle = state => state.toggle;

export const getToggle = createSelector(
  [selectorToggle],
  toggle => toggle.toggle,
);
