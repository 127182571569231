import gql from 'graphql-tag';

export const GET_VIEW_COUNTS = gql`
  query ViewCount {
    viewCount {
      count
      pageUrl
      users {
        id
        timestamp
        views
      }
    }
  }
`;
