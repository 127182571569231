import React from 'react';
// import { func } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import logoH from 'Images/logo/logo-h.svg';
import { AppBar, Toolbar, Grid } from 'components/base';
import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => {
  const borderStyle = `1px solid ${theme.palette.divider}`;
  return {
    appBar: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: borderStyle,
      height: '80px',
    },
    grow: {
      flexGrow: 1,
    },
    logo: {
      width: '135px',
    },
    toolbar: {
      flexGrow: 1,
      flexWrap: 'wrap',
      height: '80px',
    },
    searchBar: {
      borderLeft: borderStyle,
      borderRight: borderStyle,
      height: '80px',
    },
  };
});

const AppHeader = () => {
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <div className={classes.logo}>
              <Link to="/" style={{ height: '27px', display: 'block' }}>
                <img src={logoH} alt="logo" />
              </Link>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

AppHeader.propTypes = {
  // handleLogout: func.isRequired,
};

export default withRouter(AppHeader);
