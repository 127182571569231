import { object, string } from 'yup';

export const signInInitialValues = {
  username: '',
  password: '',
};

export const signInValidationSchema = object({
  username: string()
    .label('Username')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(),
  password: string()
    .label('Password')
    .required()
    .min(2, 'Seems a bit short...')
    .max(50, 'The password is too long!'),
});
