import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

const ButtonColorPicker = ({ color, handleChangeColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(oldDisplay => !oldDisplay);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = newColor => {
    handleChangeColor(newColor);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: color,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: handleChangeColor && 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <>
      <div
        role="button"
        style={styles.swatch}
        onClick={handleChangeColor && handleClick}
      >
        <div style={styles.color} />
      </div>
      {handleChangeColor && displayColorPicker ? (
        <div style={styles.popover}>
          <div role="button" style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </>
  );
};

export default ButtonColorPicker;
