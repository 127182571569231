import React, { useState } from 'react';
import { object, oneOfType, arrayOf, node } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from 'config/theme';
import { AppBar, Typography, Grid, Container } from 'components/base';
import { getCookie } from 'helpers/cookies';
import { COOKIES } from 'helpers/constants';

import AppFooter from './components/footer';
import AppHeader from './components/header';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '100%',
  },
  main: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    flex: 1,
    margin: theme.spacing(2, 0),
    marginTop: 0,
    marginBottom: 0,
  },
  rootPanel: {
    height: '100%',
    minHeight: '100vh',
    backgroundColor: '#E5E5E5',
  },
}));

const AppLayout = ({ children, location }) => {
  const classes = useStyles();
  // TODO: [new authen warning] to be removed later.
  const [notLoggedWithNewAuth] = useState(
    getCookie(COOKIES.names.userInfo) &&
      getCookie('newAuthentication') !== 'yes',
  );
  const isNotPanel = !location.pathname.includes('/panel');

  return isNotPanel ? (
    <div className={classes.root}>
      <Grid container className={classes.fullHeight}>
        {notLoggedWithNewAuth && (
          <AppBar position="sticky" style={{ padding: '10px' }}>
            <Typography color="secondary" align="center">
              <p>
                We have made a major upgrade to Cocoon&#39;s authentication
                mechanism.
              </p>
              <p>
                Please <b>sign out and sign in again</b> for this to take
                effect.
              </p>
              <p>Thank you and we apologize for any inconvenience caused.</p>
            </Typography>
          </AppBar>
        )}
        <AppHeader />
        <Container maxWidth={false} disableGutters className={classes.main}>
          {children}
        </Container>
        <AppFooter />
      </Grid>
    </div>
  ) : (
    <div className={classes.rootPanel}>{children}</div>
  );
};

// console complained about element
AppLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  location: object.isRequired,
};

export default withRouter(AppLayout);
