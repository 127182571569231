import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { announcements: [] };

// ACTION CREATORS
export const { initAnnouncements, setAnnouncements } = createActions({
  INIT_ANNOUNCEMENTS: undefined,
  SET_ANNOUNCEMENTS: announcements => ({ announcements }),
});

// REDUCERS
export default handleActions(
  {
    [initAnnouncements]() {
      return { ...initState };
    },
    [setAnnouncements](state, { payload: { announcements } }) {
      return { ...state, announcements };
    },
  },
  initState,
);

// SELECTORS
const selectorAnnouncement = state => state.announcement;

export const getAnnouncements = createSelector(
  [selectorAnnouncement],
  announcement => announcement.announcements,
);
