import React from 'react';
import { useMutation } from 'react-apollo';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from 'components/base';
import { closeDialog } from 'reducers/dialogReducer';
import { useUnload } from 'hooks';
import { LOGOUT } from 'apollo/mutations/auth-mutation';
import { logOut } from 'config/auth/auth0';
import ReduxAuth from 'config/redux/auth';

const LogoutDialog = ({ open, handleCloseDialog }) => {
  // Prevent user reload when dialog is opened
  useUnload(handleCloseDialog);
  const [logout] = useMutation(LOGOUT);
  const history = useHistory();

  const handleLogout = async () => {
    await logout();
    ReduxAuth.logout(history);
    logOut();
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <DialogTitle disableTypography id="logout-dialog-title">
        <Typography color="error" variant="h6">
          Logout
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="logout-dialog-description">
          Are you sure you want to log out?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleLogout} color="inherit" autoFocus>
            Confirm
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

LogoutDialog.propTypes = {
  open: bool.isRequired,
  handleCloseDialog: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleCloseDialog: () => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(LogoutDialog);
