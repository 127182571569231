import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router';

import { LOGOUT } from 'apollo/mutations/auth-mutation';
import { logOut } from 'config/auth/auth0';
import ReduxAuth from 'config/redux/auth';

import AppHeader from './AppHeader';

const AppHeaderContainer = () => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const [logout] = useMutation(LOGOUT);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {
    await logout();
    ReduxAuth.logout(history);
    logOut();
  };

  return (
    <AppHeader
      onClick={toggle}
      isOpen={collapsed}
      handleLogout={handleLogout}
    />
  );
};

export default AppHeaderContainer;
