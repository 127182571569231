import gql from 'graphql-tag';

export const GET_IAC_PROJECT_CATEGORIES = gql`
  query projectCategories($page: Int!, $limit: PaginationLimit) {
    projectCategories(page: $page, limit: $limit) {
      id
      name
      displayColor
    }
  }
`;
