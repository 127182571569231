export const GET_METHOD = 'GET';
export const POST_METHOD = 'POST';
export const { GRAPHQL_ENDPOINT, REST_ENDPOINT } = process.env;

// Authorization purpose ONLY.
export const Everyone = [1, 2];
export const Admin = [1];

export const updateAttachmentDropzone = {
  width: '100%',
  height: 50,
  borderWidth: 2,
  borderColor: '#e3e3e3',
  borderStyle: 'dashed',
  textAlign: 'center',
  display: 'inline-block',
  cursor: 'pointer',
};

export const acceptedBasicFiles =
  '.jpg, .png, .jpeg, .docx, .doc, .xlsx, .xls, .csv, .pdf, .zip';

export const acceptedImageFiles = '.jpg, .png, .jpeg .gif';

export const TAG_INPUT = {
  tag_length: '20',
  keyCodes: {
    enter: 13,
  },
};

export const BASE_COLORS = {
  main: '#1d8ece',
};

export const FILE_UPLOAD_TYPE = {
  projectPicture: 'ProjectPicture',
  updateAttachment: 'UpdateAttachment',
  userAvatar: 'UserAvatar',
  backgroundPicture: 'BackgroundPicture',
};

export const COOKIES = {
  names: {
    username: 'username',
    userInfo: 'user-info',
  },
  domain: process.env.DOMAIN_NAME || 'localhost',
};

export const PAGINATION = {
  three: 3,
  five: 5,
};

export const PROJECT_TYPE = {
  ALL: 'ALL',
  FEAT_TYPE: 'FEATURED',
  SUBS_TYPE: 'SUBSCRIPTIONS',
  DISC_TYPE: 'DISCOVER',
  WANT_TYPE: 'WANT',
};

export const ROLES = {
  MEMBER: 'member',
  VIEWER: 'viewer',
};

export const CURRENT_ROLE = ROLES.MEMBER;

export const DIALOG = {
  LOGOUT: 'LOGOUT',
  DEACTIVATE: 'DEACTIVATE',
  PRIVACY: 'PRIVACY',
  DELETE: 'DELETE',
  LIST: 'LIST',
  LIST_PROJECT: 'LIST_PROJECT',
  ADD_TOPIC: 'ADD_TOPIC',
  DELETE_LIST_PROJECT: 'DELETE_LIST_PROJECT',
  UPDATE_PUBLISH: 'UPDATE_PUBLISH',
  VIEW_EXPERIENCE_CONTEST_DETAILS: 'VIEW_EXPERIENCE_CONTEST_DETAILS',
  REMOVE_PROJECT_FROM_LIST: 'REMOVE_PROJECT_FROM_LIST',
  EDIT_PROFILE: 'EDIT_PROFILE',
  INVITE_PROJECT_MEMBER: 'INVITE_PROJECT_MEMBER',
  // DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER'
};

export const DETAILS_TYPE = {
  EXPERIENCES: 'EXPERIENCES',
  CONTESTS: 'CONTESTS',
};
