import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from 'config/theme';
import { Typography, Divider, Box, Container } from 'components/base';

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(3, 2),
    '& > *:first-child': {
      '& > * + *': {
        marginLeft: theme.spacing(3),
      },
      '& > *:hover': {
        fontWeight: 'bold',
        transition: 'all 0.3s',
      },
    },
  },
}));

const AppFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation('glossary');
  return (
    <Container maxWidth="xl">
      <Divider variant="middle" />
      <footer className={classes.footer}>
        <Box mt={1}>
          <Typography variant="h6" align="center" color="textSecondary">
            {t('copyright')}
          </Typography>
        </Box>
      </footer>
    </Container>
  );
};

export default withRouter(AppFooter);
