import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { Formik } from 'formik';
import to from 'await-to-js';
import { connect } from 'react-redux';

import { openSnackbar } from 'reducers/snackbarReducer';
import { openBackdrop, closeBackdrop } from 'reducers/backdropReducer';
import { passwordSignIn } from 'config/auth/auth0';

import SignIn from './SignIn';
import { signInInitialValues, signInValidationSchema } from './helpers/formik';

const SignInContainer = ({
  handleOpenSnackbar,
  handleCloseBackdrop,
  handleOpenBackdrop,
}) => {
  useEffect(() => {
    handleCloseBackdrop();
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    handleOpenBackdrop();

    const err = await to(
      passwordSignIn({
        username: values.username.trim(),
        password: values.password.trim(),
      }),
    );

    if (err) {
      handleCloseBackdrop();
      const { description } = err[0];
      setErrors({ general: description });
      handleOpenSnackbar('error', description);
    }
  };

  return (
    <Formik
      initialValues={signInInitialValues}
      validationSchema={signInValidationSchema}
      onSubmit={handleSubmit}
    >
      {props => <SignIn {...props} />}
    </Formik>
  );
};

SignInContainer.propTypes = {
  handleOpenSnackbar: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleOpenSnackbar: (type, content) => dispatch(openSnackbar(type, content)),
  handleOpenBackdrop: () => dispatch(openBackdrop()),
  handleCloseBackdrop: () => dispatch(closeBackdrop()),
});

export default connect(null, mapDispatchToProps)(SignInContainer);
