import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { categories: [] };

// ACTION CREATORS
export const { initCategories, setCategories } = createActions({
  INIT_CATEGORIES: undefined,
  SET_CATEGORIES: categories => ({ categories }),
});

// REDUCERS
export default handleActions(
  {
    [initCategories]() {
      return { ...initState };
    },
    [setCategories](state, { payload: { categories } }) {
      return { ...state, categories };
    },
  },
  initState,
);

// SELECTORS
// const selectorCategory = state => state.category;
const selectorCategory = state => state.category;

export const getCategories = createSelector(
  [selectorCategory],
  category => category.categories,
);
