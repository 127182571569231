import React from 'react';
import { arrayOf, object, bool, func } from 'prop-types';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';

import { makeStyles } from 'config/theme';
import { Box } from 'components';

import Page from './components/Page';

const useStyles = makeStyles(theme => ({
  table: {
    '& th': {
      backgroundColor: '#01579b',
      color: theme.palette.common.white,
      fontWeight: 700,
      textAlign: 'center',

      '& *, & .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white,
      },
    },
    '& .Component-horizontalScrollContainer-32': {
      overflow: 'visible !important',
    },
  },
}));

const Analysis = ({ viewCount, refetch, loading, handleOpenSnackbar }) => {
  const classes = useStyles();
  const columns = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
    },
    {
      title: 'Page',
      field: 'pageUrl',
      render: rowData => <Page data={rowData.column} url={rowData.pageUrl} />,
    },
    {
      title: 'Number View',
      field: 'count',
    },
    {
      title: 'Last View',
      field: 'lastView',
    },
  ];

  const options = {
    // sorting: true,
    filtering: true,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    showTitle: false,
    toolbarButtonAlignment: 'left',
    padding: 'dense',
    addRowPosition: 'first',
    headerStyle: {
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#384885',
    },
  };

  const actions = [
    {
      icon: () => <RefreshIcon />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
  ];

  return (
    <Box className={classes.table}>
      <MaterialTable
        data={viewCount}
        title="View Count"
        isLoading={loading}
        columns={columns}
        options={options}
        actions={actions}
      />
    </Box>
  );
};

Analysis.propTypes = {
  loading: bool.isRequired,
  viewCount: arrayOf(object),
  refetch: func.isRequired,
  handleOpenSnackbar: func.isRequired,
};

export default Analysis;
