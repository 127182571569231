import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const TOGGLE_CERTIFY_USER = gql`
  mutation certifyUser($id: ID!, $isCertified: Boolean!) {
    certifyUser(id: $id, isCertified: $isCertified) {
      status
      description
    }
  }
`;

export const MAKE_USER = gql`
  mutation makeUser($id: ID!) {
    makeUser(id: $id) {
      status
      description
    }
  }
`;

export const MAKE_PARTNER = gql`
  mutation makePartner($id: ID!) {
    makePartner(id: $id) {
      status
      description
    }
  }
`;

export const MAKE_ADMIN = gql`
  mutation makeAdmin($id: ID!) {
    makeAdmin(id: $id) {
      status
      description
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($data: UserInput!) {
    updateUser(data: $data) {
      status
      description
    }
  }
`;
