import gql from 'graphql-tag';

export const CREATE_IAC_ANNOUNCEMENT = gql`
  mutation createAnnouncement($data: AnnouncementInput!) {
    createAnnouncement(data: $data) {
      id
      name
      text
      isActive
    }
  }
`;

export const DELETE_IAC_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id) {
      status
      description
    }
  }
`;

export const EDIT_IAC_ANNOUNCEMENT = gql`
  mutation editAnnouncement($id: ID!, $data: AnnouncementInput!) {
    editAnnouncement(id: $id, data: $data) {
      id
      name
      text
      isActive
    }
  }
`;
