import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { projects: [] };

// ACTION CREATORS
export const { initProjects, setProjects } = createActions({
  INIT_PROJECTS: undefined,
  SET_PROJECTS: projects => ({ projects }),
});

// REDUCERS
export default handleActions(
  {
    [initProjects]() {
      return { ...initState };
    },
    [setProjects](state, { payload: { projects } }) {
      return { ...state, projects };
    },
  },
  initState,
);

// SELECTORS
const selectorProject = state => state.project;

export const getProjects = createSelector(
  [selectorProject],
  project => project.projects,
);
