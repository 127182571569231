import React from 'react';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { arrayOf, func, object, string } from 'prop-types';

import {
  Chip,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
} from 'components/base';
import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (field, columns, theme) => {
  return {
    fontWeight: _.includes(columns, field)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
};

const InputColumns = ({ columns, value, onChange }) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="project-columns">Selected Columns</InputLabel>
      <Select
        labelId="project-columns"
        id="project-columns-input"
        multiple
        value={value}
        onChange={onChange}
        input={<Input id="select-multiple-project-columns" />}
        renderValue={selected => {
          return (
            <div className={classes.chips}>
              {selected.map(val => {
                const col = _.find(columns, { field: val });
                return (
                  <Chip
                    size="small"
                    key={val}
                    label={col.title}
                    className={classes.chip}
                  />
                );
              })}
            </div>
          );
        }}
        MenuProps={MenuProps}
      >
        {columns.map(({ title, field }) => (
          <MenuItem
            key={field}
            value={field}
            style={getStyles(field, value, theme)}
          >
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

InputColumns.propTypes = {
  columns: arrayOf(object).isRequired,
  value: arrayOf(string).isRequired,
  onChange: func.isRequired,
};

export default InputColumns;
