import React from 'react';
import { number, string } from 'prop-types';

import { Box, CircularProgress } from 'components/base';

const CircularCenterLoader = ({ height, color }) => (
  <Box
    height={height}
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress color={color} />
  </Box>
);

CircularCenterLoader.propTypes = {
  height: number,
  color: string,
};

CircularCenterLoader.defaultProps = {
  height: 100,
  color: 'inherit',
};

export default CircularCenterLoader;
