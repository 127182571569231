import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { string, func } from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';

import { makeStyles } from 'config/theme';
import { Box } from 'components/base';

import UploadAdapter from './UploadAdapter';

const useStyles = makeStyles({
  view: {
    '& .ck.ck-editor__top.ck-reset_all': {
      display: 'none',
    },
    '& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)': {
      border: 'none',
    },
    '& .ck.ck-editor__editable_inline': {
      padding: 0,
    },
  },
});

const CocoonEditor = ({ data, handleChangeData }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.view]: !handleChangeData,
      })}
    >
      <CKEditor
        editor={ClassicEditor}
        data={data}
        disabled={!handleChangeData}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'blockQuote',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            'mediaEmbed',
            '|',
            'undo',
            'redo',
          ],
          link: {
            decorators: {
              addTargetToExternalLinks: {
                mode: 'automatic',
                callback: url => /^(https?:)?\/\//.test(url),
                attributes: {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
              },
            },
          },
        }}
        onInit={editor => {
          editor.plugins.get('FileRepository').createUploadAdapter = loader => {
            return UploadAdapter(loader);
          };
        }}
        onChange={(event, editor) => {
          if (handleChangeData) {
            handleChangeData(editor.getData());
          }
        }}
      />
    </Box>
  );
};

CocoonEditor.propTypes = {
  data: string.isRequired,
  handleChangeData: func,
};

CocoonEditor.defaultProps = {
  handleChangeData: undefined,
};

export default CocoonEditor;
