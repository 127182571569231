import { useState, useEffect } from 'react';

const useSetTimer = () => {
  const [activeTime, setActiveTime] = useState(0);
  const [idleTime, setIdleTime] = useState(0);
  const [activeTimerActive, setActiveTimerActive] = useState(false);
  const [idleTimerActive, setIdleTimerActive] = useState(false);

  useEffect(() => {
    let activeTimeInterval = null;
    let idleTimeInterval = null;

    if (activeTimerActive) {
      activeTimeInterval = setInterval(() => {
        setActiveTime(activeTime + 1);
      }, 1000);
    } else {
      clearInterval(activeTimeInterval);
      setActiveTime(0);
    }

    if (idleTimerActive) {
      idleTimeInterval = setInterval(() => {
        setIdleTime(idleTime + 1);
      }, 1000);
    } else {
      clearInterval(idleTimeInterval);
      setIdleTime(0);
    }

    return () => {
      clearInterval(activeTimeInterval);
      clearInterval(idleTimeInterval);
    };
  }, [activeTime, idleTime, activeTimerActive, idleTimerActive]);

  return [activeTime, idleTime, setActiveTimerActive, setIdleTimerActive];
};

export default useSetTimer;
