import React from 'react';
import { object, arrayOf, bool, func } from 'prop-types';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';

import { useTheme } from 'config/theme';
import { Chip, Box, LightTooltip, TextField } from 'components';
import { Button } from 'components/base';

import useStyles from './PartnersStyle';
import CreatePartner from './components/CreatePartner';

const Partners = ({
  partners,
  loading,
  showForm,
  setShowForm,
  handleOpenSnackbar,
  handleOpenBackdrop,
  handleCloseBackdrop,
  refetch,
  updateUser,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const columns = [
    {
      title: 'ID',
      field: 'id',
      editable: 'never',
      type: 'numeric',
      defaultSort: 'asc',
      cellStyle: { maxWidth: 15 },
    },
    {
      title: 'Partner Name',
      field: 'partnerName',
      emptyValue: 'None',
      editable: 'onUpdate',
      type: 'string',
      editComponent: comp => (
        <TextField
          value={comp.value}
          onChange={e => comp.onChange(e.target.value)}
        />
      ),
    },
    {
      title: 'Partner Description',
      field: 'description',
      emptyValue: 'None',
      editable: 'onUpdate',
      type: 'string',
      editComponent: comp => (
        <TextField
          value={comp.value}
          onChange={e => comp.onChange(e.target.value)}
        />
      ),
    },
    // TODO: implement later.
    // {
    //   title: 'Tags',
    //   field: 'tags',
    //   editable: 'never',
    //   render: rowData => {
    //     const renderTags = no => {
    //       const arrays = no ? rowData.tags.slice(0, no) : rowData.tags;

    //       return arrays.map(tag => (
    //         <Chip
    //           key={tag.id}
    //           label={tag.tagName}
    //           size="small"
    //           style={{
    //             backgroundColor: '#fff',
    //             color: '#000',
    //             margin: 2,
    //             border: `2px solid ${theme.palette.primary.main}`,
    //           }}
    //         />
    //       ));
    //     };
    //     return (
    //       <Box className={classes.tags}>
    //         {renderTags(3)}
    //         {rowData.tags.length > 3 && (
    //           <LightTooltip
    //             title={<Box width={200}>{renderTags()}</Box>}
    //             placement="right"
    //           >
    //             <Chip
    //               label="View more..."
    //               size="small"
    //               style={{
    //                 backgroundColor: 'transparent',
    //                 color: '#000',
    //                 margin: 2,
    //               }}
    //             />
    //           </LightTooltip>
    //         )}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   title: 'Members',
    //   field: 'members',
    //   editable: 'never',
    //   render: rowData => {
    //     const renderMembers = no => {
    //       const arrays = no ? rowData.members.slice(0, no) : rowData.members;

    //       return arrays.map(member => (
    //         <Chip
    //           key={member.id}
    //           label={member.email}
    //           size="small"
    //           style={{
    //             backgroundColor: '#fff',
    //             color: '#000',
    //             margin: 2,
    //             border: `2px solid ${theme.palette.primary.main}`,
    //           }}
    //         />
    //       ));
    //     };
    //     return (
    //       <Box className={classes.tags}>
    //         {renderMembers(3)}
    //         {rowData.members.length > 3 && (
    //           <LightTooltip
    //             title={<Box width={200}>{renderMembers()}</Box>}
    //             placement="right"
    //           >
    //             <Chip
    //               label="View more..."
    //               size="small"
    //               style={{
    //                 backgroundColor: 'transparent',
    //                 color: '#000',
    //                 margin: 2,
    //               }}
    //             />
    //           </LightTooltip>
    //         )}
    //       </Box>
    //     );
    //   },
    // },
  ];

  const options = {
    sorting: true,
    filtering: true,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    showTitle: false,
    toolbarButtonAlignment: 'left',
    padding: 'dense',
    addRowPosition: 'first',
    headerStyle: {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#384885',
    },
  };

  const editable = {
    onRowUpdate: async (newData, oldData) => {
      const { id: userId, email, partnerName, description } = newData;
      const {
        partnerName: oldPartnerName,
        description: oldDescription,
      } = oldData;
      if (partnerName !== oldPartnerName || description !== oldDescription) {
        updateUser(userId, email, partnerName, description);
      }
    },
  };

  const actions = [
    {
      icon: () => <RefreshIcon />,
      tooltip: 'Refresh',
      isFreeAction: true,
      onClick: refetch,
    },
  ];

  return (
    <Box className={classes.table}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowForm(!showForm)}
        className={classes.button}
        disabled
      >
        {showForm ? 'Back' : '+ Create Partner'}
      </Button>
      {showForm ? (
        <CreatePartner
          handleOpenSnackbar={handleOpenSnackbar}
          handleOpenBackdrop={handleOpenBackdrop}
          handleCloseBackdrop={handleCloseBackdrop}
          setShowForm={setShowForm}
          refetch={refetch}
        />
      ) : (
        <MaterialTable
          data={[...partners]}
          title="Partners"
          isLoading={loading}
          columns={columns}
          options={options}
          editable={editable}
          actions={actions}
        />
      )}
    </Box>
  );
};

Partners.propTypes = {
  partners: arrayOf(object).isRequired,
  loading: bool.isRequired,
  showForm: bool.isRequired,
  setShowForm: func.isRequired,
  handleOpenSnackbar: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  refetch: func.isRequired,
  updateUser: func.isRequired,
};

export default Partners;
