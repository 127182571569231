// import { fileApi } from 'api';
import log from 'helpers/log';

// will do later
const UploadAdapter = loader => {
  return {
    // Starts the upload process.
    upload: async () => {
      return loader.file.then(async file => {
        log.info(file);
        // try {
        //   const awsUploadedFile = await fileApi.uploadFile(
        //     {
        //       type: FILE_UPLOAD_TYPE.userAvatar,
        //       contentType: file.type,
        //       fileName: file.name,
        //       userId: profile.id,
        //     },
        //     awsUploadedFile,
        //   );
        // } catch (errorAws) {
        //   handleOpenSnackbar('error', errorAws.message);
        // }
      });
    },

    abort: () => {},
  };
};

export default UploadAdapter;
