import React, { useState } from 'react';
import { func, bool, object } from 'prop-types';
import { connect } from 'react-redux';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  GridList,
  GridListTile,
  Avatar,
} from 'components/base';
import Image from 'components/image/Image';
import { closeDialog } from 'reducers/dialogReducer';
import { useUnload } from 'hooks';
import { makeStyles } from 'config/theme';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'none',
    },
  },
  gridList: {
    width: 600,

    '& .MuiGridListTile-tile > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: 6,
    },
  },
});

const ListDialog = ({ open, options, handleCloseDialog }) => {
  const classes = useStyles();
  const [isDownload, setIsDownload] = useState(false);
  const { title, list, handleOnClickItem, isGallery } = options;

  // Prevent user reload when dialog is opened
  useUnload(!isDownload && handleCloseDialog);

  const handleOnDownloadClick = () => {
    setIsDownload(true);
  };

  const handleImageZoom = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderGallery = () =>
    list && (
      <GridList cellHeight={160} className={classes.gridList} cols={3}>
        {list.map((item, index) => (
          <GridListTile key={item.id} cols={1}>
            <Image
              src={item.assetLink}
              alt={item.name}
              onClick={e => handleImageZoom(e, index)}
              aspectRatio={1}
              hasDownload
              hasZoom
              isHoverable
              onZoomCLick={e => handleImageZoom(e, index)}
              onDownloadClick={handleOnDownloadClick}
            />
          </GridListTile>
        ))}
      </GridList>
    );

  const renderList = () =>
    list && (
      <List dense>
        {list.map((item, index) => (
          <ListItem key={index} button onClick={handleOnClickItem}>
            <ListItemAvatar>
              <Avatar
                alt={`${item.firstname} ${item.lastname}`}
                src={item.avatarLink}
              />
            </ListItemAvatar>
            <ListItemText
              id={item.username}
              primary={`${item.firstname} ${item.lastname}`}
            />
          </ListItem>
        ))}
      </List>
    );

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      className={classes.dialog}
      aria-labelledby="list-dialog-title"
      aria-describedby="list-dialog-description"
    >
      <DialogTitle disableTypography id="list-dialog-title">
        <Typography color="primary" variant="h6">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isGallery ? renderGallery() : renderList()}
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

ListDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleCloseDialog: () => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(ListDialog);
