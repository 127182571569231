import { clearAllLocalStorage } from 'helpers/localStorage';

class ReduxAuth {
  constructor() {
    this.store = null;
    this.persistor = null;
  }

  init = (store, persistor) => {
    this.store = store;
    this.persistor = persistor;
  };

  logout = history => {
    this.store.dispatch({ type: 'RESET' });
    sessionStorage.removeItem('persist:root');
    clearAllLocalStorage();
    const purgePromise = this.persistor.purge();
    history.push('/');
    return Promise.all([purgePromise]);
  };
}

export default new ReduxAuth();
