/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { func, bool, number, arrayOf, object, string } from 'prop-types';
import clsx from 'clsx';

import logo from 'Images/logo/logo.svg';
import logoH from 'Images/logo/logo-h.svg';
import { makeStyles } from 'config/theme';
import {
  Drawer,
  List,
  Divider,
  ExitToAppIcon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from 'components/base';
import { LightTooltip } from 'components';

const useStyles = drawerWidth =>
  makeStyles(theme => ({
    toolbarIcon: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2, 2),
      ...theme.mixins.toolbar,

      '& h6': {
        color: theme.palette.primary.main,
      },
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      '& .MuiListItem-root': {
        width: 'auto',
        margin: theme.spacing(0, 1),
        borderRadius: 6,

        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,

          '& *': {
            color: theme.palette.common.white,
            fontWeight: 700,
          },
        },
      },

      '& .MuiListItemIcon-root': {
        minWidth: theme.spacing(6),
      },

      '& img': {
        height: 68,
        [theme.breakpoints.up('sm')]: {
          height: 59,
        },
      },
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.down('sm')]: {
        width: 0,
      },
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

const DashboardDrawer = ({
  drawerWidth,
  routesMap,
  open,
  selectedPath,
  handleListItemClick,
  handleOpenLogoutDialog,
}) => {
  const classes = useStyles(drawerWidth)();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        {open ? (
          <>
            <img src={logoH} alt="logo-long" />
            <Box mt={1} component={Typography} variant="h6">
              Admin Dashboard
            </Box>
          </>
        ) : (
          <img src={logo} alt="logo" />
        )}
      </div>
      <Divider />
      <List>
        {routesMap.map(route => (
          <LightTooltip key={route.path} title={route.name} placement="right">
            <ListItem
              button
              selected={selectedPath === route.path}
              onClick={event => handleListItemClick(event, route)}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          </LightTooltip>
        ))}
      </List>
      <Divider />
      <List>
        <LightTooltip title="Log out" placement="right">
          <ListItem button onClick={handleOpenLogoutDialog}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </LightTooltip>
      </List>
    </Drawer>
  );
};

DashboardDrawer.propTypes = {
  drawerWidth: number.isRequired,
  open: bool.isRequired,
  routesMap: arrayOf(object).isRequired,
  selectedPath: string.isRequired,
  handleListItemClick: func.isRequired,
  handleOpenLogoutDialog: func.isRequired,
};

export default DashboardDrawer;
