import { withStyles } from 'config/theme';
import { Tooltip } from 'components/base';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '1px 1px 3px 0px rgb(0,0,0)',
    fontSize: 12,
  },
}))(Tooltip);

const LightTooltipImage = withStyles(theme => ({
  tooltip: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    boxShadow: '1px 1px 3px 0px rgb(0,0,0)',
    maxWidth: 'none',
  },
}))(Tooltip);

export { LightTooltip, LightTooltipImage };
