import React from 'react';
import { func, bool, arrayOf, object } from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { makeStyles } from 'config/theme';
import { Container, Grid } from 'components/base';
import { AppFooter } from 'components';

import DashboardAppbar from './components/DashboardAppbar';
import DashboardDrawer from './components/DashboardDrawer';

const DRAWER_WIDTH = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard = ({
  open,
  routesMap,
  selectedRoute,
  handleListItemClick,
  handleToggleDrawer,
  handleOpenLogoutDialog,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DashboardAppbar
        drawerWidth={DRAWER_WIDTH}
        open={open}
        title={selectedRoute.name}
        handleToggleDrawer={handleToggleDrawer}
        handleOpenLogoutDialog={handleOpenLogoutDialog}
      />
      <DashboardDrawer
        drawerWidth={DRAWER_WIDTH}
        open={open}
        routesMap={routesMap}
        selectedPath={selectedRoute.path}
        handleListItemClick={handleListItemClick}
        handleOpenLogoutDialog={handleOpenLogoutDialog}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Switch>
                {routesMap.map(route => (
                  <Route
                    key={route.path}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                ))}
                <Redirect exact path="/" to="/projects" />
              </Switch>
            </Grid>
          </Grid>
          <AppFooter />
        </Container>
      </main>
    </div>
  );
};

Dashboard.propTypes = {
  open: bool.isRequired,
  routesMap: arrayOf(object).isRequired,
  selectedRoute: object.isRequired,
  handleListItemClick: func.isRequired,
  handleOpenLogoutDialog: func.isRequired,
  handleToggleDrawer: func.isRequired,
};

export default Dashboard;
