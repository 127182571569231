import gql from 'graphql-tag';

export const GET_IAC_ANNOUNCEMENTS = gql`
  query announcements($page: Int!, $limit: PaginationLimit) {
    announcements(page: $page, limit: $limit) {
      id
      name
      text
      isActive
    }
  }
`;
