export default {
  common: {
    or: 'Or',
    and: 'And',
    yes: 'Yes',
    no: 'No',
  },
  button: {
    register: 'Register',
    save: 'Save',
    cancel: 'Cancel',
  },
  error: {
    '404': 'Not Found',
    unspecified: 'Unspecified',
  },
  sign: {
    forgot: 'Forgot password?',
    up: {
      title: 'Sign Up',
      quest: "Don't have an account? Sign up",
    },
    in: {
      title: 'Sign In Admin',
      quest: 'Already have an account? Sign in',
    },
    google: 'Sign in with Google',
  },
};
