// React component and hook which listens to beforeunload on the window when mounted.
import { useEffect } from 'react';

import useLatest from './useLatest';

const useUnload = (handler = () => {}) => {
  if (process.env.NODE_ENV !== 'production' && typeof handler !== 'function') {
    throw new TypeError(
      `Expected "handler" to be a function, not ${typeof handler}.`,
    );
  }

  const handlerRef = useLatest(handler);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleBeforeunload = event => {
      const returnValue = handlerRef.current(event);

      // Chrome requires `returnValue` to be set.
      if (event.defaultPrevented) {
        event.returnValue = '';
      }

      if (typeof returnValue === 'string') {
        event.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);
};

export default useUnload;
