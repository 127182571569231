import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxReset from 'redux-reset';

import reducers from 'reducers';

import ReduxAuth from './auth';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducers = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  if (action.type === 'persist/PURGE') {
    // eslint-disable-next-line no-underscore-dangle
    delete state._persist;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error,
    });
    middlewares.push(logger);
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, reduxReset()];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers,
  );
  const persistor = persistStore(store);

  ReduxAuth.init(store, persistor);

  return { store, persistor };
}
