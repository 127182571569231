// A React helper hook for storing latest value in ref object (updated in useEffect's callback)
import { useRef } from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

const useLatest = value => {
  const ref = useRef(value);

  useIsomorphicLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
};

export default useLatest;
