import React, { useCallback, useState, useEffect } from 'react';
import { arrayOf, object, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useLazyQuery, useMutation } from 'react-apollo';

import {
  TextField,
  Typography,
  FormControl,
  Button,
  Chip,
  Autocomplete,
  CircularProgress,
} from 'components/base';
import { GET_SEARCH_PROJECTS } from 'apollo/queries/project-query';
import { CREATE_CONTEST } from 'apollo/mutations/contest-mutation';
import { GET_USERS_BY_USERNAME_EMAIL } from 'apollo/queries/user-query';
import { useDebounce } from 'hooks';
import { cookieUserId } from 'helpers/auth';

import useStyles from './CreateConstestStyle';

const CreateContest = ({
  refetch,
  handleOpenSnackbar,
  handleOpenBackdrop,
  handleCloseBackdrop,
  setShowForm,
}) => {
  const classes = useStyles();
  const [newContest, setNewContest] = useState({
    name: '',
    description: '',
    tags: [],
  });

  const [currentTag, setCurrentTag] = useState('');

  // TODO: write a hook for this
  const [searchProject, setSearchProject] = useState('');
  const debouncedSearchProject = useDebounce(searchProject, 500);
  const [projectOptions, setProjectOptions] = useState([]);

  const [searchMember, setSearchMember] = useState('');
  const debouncedSearchMember = useDebounce(searchMember, 500);
  const [memberOptions, setMemberOptions] = useState([]);

  const [searchPartner, setSearchPartner] = useState('');
  const debouncedSearchPartner = useDebounce(searchPartner, 500);
  const [partnerOptions, setPartnerOptions] = useState([]);

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState({});

  const [
    getProjectsByNameCb,
    { data: projectsData, loading: loadingProjects },
  ] = useLazyQuery(GET_SEARCH_PROJECTS, {
    fetchPolicy: 'network-only',
  });

  const [
    getUsersByUsernameEmailCb,
    { data: usersData, loading: loadingUsers },
  ] = useLazyQuery(GET_USERS_BY_USERNAME_EMAIL, {
    fetchPolicy: 'network-only',
  });

  const [
    getPartnersCb,
    { data: partnersData, loading: loadingPartners },
  ] = useLazyQuery(GET_USERS_BY_USERNAME_EMAIL, {
    fetchPolicy: 'network-only',
  });

  const [createContest] = useMutation(CREATE_CONTEST);

  useEffect(() => {
    if (projectsData) {
      setProjectOptions([...projectsData.projects]);
    }
    if (usersData) {
      setMemberOptions(usersData.usersByUsernameEmail);
    }
    if (partnersData) {
      setPartnerOptions(partnersData.usersByUsernameEmail);
    }
  }, [projectsData, usersData, partnersData]);

  useEffect(() => {
    if (debouncedSearchProject) {
      getProjectsByNameCb({
        variables: {
          page: 1,
          limit: 'FIVE_O',
          projectName: debouncedSearchProject,
        },
      });
    }
    if (debouncedSearchMember) {
      getUsersByUsernameEmailCb({
        variables: {
          page: 1,
          limit: 'FIVE_O',
          orderBy: 'id',
          searchTerm: debouncedSearchMember,
        },
      });
    }
    if (debouncedSearchPartner) {
      getPartnersCb({
        variables: {
          page: 1,
          limit: 'FIVE_O',
          orderBy: 'email',
          searchTerm: debouncedSearchPartner,
          isPartner: true,
        },
      });
    }
  }, [debouncedSearchProject, debouncedSearchMember, debouncedSearchPartner]);

  const onChange = useCallback((name, value) => {
    setNewContest({ ...newContest, [name]: value });
  });

  const handleAddNewTag = () => {
    const copy = [...newContest.tags];
    copy.push({ id: copy.length + 1, tagName: currentTag.trim() });
    setNewContest({ ...newContest, tags: copy });
    setCurrentTag('');
  };

  const handleDeleteTag = tag => {
    const { id } = tag;
    const copy = [...newContest.tags].filter(t => t.id !== id);
    setNewContest({ ...newContest, tags: copy });
  };

  const handleAddContest = async () => {
    try {
      handleOpenBackdrop();
      const contestRequest = {
        name: newContest.name,
        description: newContest.description,
        tags: newContest.tags.map(({ tagName }) => tagName),
        creatorId: selectedPartner.id || cookieUserId,
        projectsIds: selectedProjects.map(({ id }) => id),
        membersIds: selectedMembers.map(({ id }) => id),
      };

      await createContest({
        variables: { data: { ...contestRequest } },
      });
      handleCloseBackdrop();
      handleOpenSnackbar(
        'success',
        'Sucessfully created contest! Redirecting...',
      );
      refetch();
      setTimeout(() => {
        setShowForm(false);
      }, 1000);
    } catch (error) {
      handleCloseBackdrop();
      handleOpenSnackbar('error', error.message);
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.formWrapper} item xs={10}>
        <Typography className={classes.formTitle} variant="h2" component="h2">
          Create Contest Form
        </Typography>

        <Typography
          className={classes.textFieldLabel}
          variant="h4"
          component="h4"
        >
          Owner
        </Typography>
        <FormControl component="fieldset" fullWidth>
          <Autocomplete
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => (option.email ? option.email : '')}
            options={partnerOptions}
            loading={loadingPartners}
            value={selectedPartner}
            id="asynchronous-partners"
            onChange={(event, value) => setSelectedPartner({ ...value })}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                placeholder="Search for a partner"
                variant="outlined"
                onChange={e => setSearchPartner(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingPartners ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          <Typography
            className={classes.textFieldLabel}
            variant="h4"
            component="h4"
          >
            Contest Name
          </Typography>
          <TextField
            value={newContest.name}
            onChange={e => onChange('name', e.target.value)}
            placeholder="Enter contest name"
            variant="outlined"
            fullWidth
            className={classes.textField}
          />

          <Typography
            className={classes.textFieldLabel}
            variant="h4"
            component="h4"
          >
            Contest Description
          </Typography>
          <TextField
            value={newContest.description}
            onChange={e => onChange('description', e.target.value)}
            placeholder="Enter contest description"
            variant="outlined"
            fullWidth
            className={classes.textField}
            multiline
          />

          <Typography
            className={classes.textFieldLabel}
            variant="h4"
            component="h4"
          >
            Tags
          </Typography>
          <TextField
            value={currentTag.replace(/\n/g, '')}
            onChange={e => setCurrentTag(e.target.value)}
            placeholder="Enter tag name"
            variant="outlined"
            fullWidth
            className={classes.textField}
            onKeyDown={e => e.keyCode === 13 && handleAddNewTag()}
            multiline
          />

          {newContest.tags.length > 0 && (
            <div className={classes.row}>
              {newContest.tags.map(tag => (
                <Chip
                  className={classes.chip}
                  key={tag.id}
                  label={tag.tagName}
                  onDelete={() => handleDeleteTag(tag)}
                />
              ))}
            </div>
          )}

          <Typography
            className={classes.textFieldLabel}
            variant="h4"
            component="h4"
          >
            Add Projects
          </Typography>

          <Autocomplete
            multiple
            value={selectedProjects}
            id="asynchronous-projects"
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => setSelectedProjects([...value])}
            getOptionLabel={({ name }) => name}
            options={projectOptions || []}
            loading={loadingProjects}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                placeholder="Search for a project"
                variant="outlined"
                onChange={e => setSearchProject(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingProjects ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          <Typography
            className={classes.textFieldLabel}
            variant="h4"
            component="h4"
          >
            Add Members
          </Typography>

          <Autocomplete
            multiple
            value={selectedMembers}
            id="asynchronous-members"
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => setSelectedMembers([...value])}
            getOptionLabel={({ email }) => email}
            options={memberOptions || []}
            loading={loadingUsers}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                placeholder="Search for a member"
                variant="outlined"
                onChange={e => setSearchMember(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingUsers ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            onClick={handleAddContest}
            disabled={!cookieUserId}
          >
            Submit
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
};

CreateContest.propTypes = {
  projects: arrayOf(object).isRequired,
  refetch: func.isRequired,
  handleOpenSnackbar: func.isRequired,
  setShowForm: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
};

export default React.memo(CreateContest);
