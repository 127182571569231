import logo from 'Images/logo/logo.svg';

export const getLightBoxImagesFromFiles = files => {
  const images = files.filter(item => item.assetType.indexOf('image') === 0);

  const lightboxImages = [];

  images.map(image =>
    lightboxImages.push({ src: image.assetLink, caption: image.name }),
  );

  return lightboxImages;
};

export const getImageList = files => {
  const images = files.filter(item => item.assetType.indexOf('image') === 0);

  const imageList = [];

  images.map(image => imageList.push(image));

  return imageList;
};

export const getOtherFiles = files => {
  const otherFiles = files.filter(item => item.assetType.indexOf('image') < 0);

  const newFiles = [];

  otherFiles.map(file => newFiles.push(file));

  return newFiles;
};

export const getImg = img => {
  return img || logo;
};

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  Number.isNaN(value) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
