import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { arrayOf, func, number, object } from 'prop-types';

import {
  Chip,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
} from 'components/base';
import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0,0,0,.5)',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (id, cats, theme) => {
  return {
    fontWeight: _.find(cats, { id })
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
};

const InputSponsors = ({ sponsors, value, onChange }) => {
  const classes = useStyles();

  const theme = useTheme();

  const [selectedSponsors, setSelectedSponsors] = useState(value);

  const handleSetSelectedSponsors = ({ target: { value: val } }) => {
    setSelectedSponsors(val);
    onChange(val);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="project-sponsors">Sponsors</InputLabel>
      <Select
        labelId="project-sponsors"
        id="project-sponsors-input"
        multiple
        value={selectedSponsors}
        onChange={handleSetSelectedSponsors}
        input={<Input id="select-multiple-project-sponsors" />}
        renderValue={selected => {
          return (
            <div className={classes.chips}>
              {selected.map(val => {
                const spon = _.find(sponsors, { id: val });
                return (
                  <Chip
                    size="small"
                    key={val}
                    label={spon.email}
                    className={classes.chip}
                  />
                );
              })}
            </div>
          );
        }}
        MenuProps={MenuProps}
      >
        {sponsors.map(({ id, email }) => (
          <MenuItem
            key={id}
            value={id}
            style={getStyles(id, selectedSponsors, theme)}
          >
            {email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

InputSponsors.propTypes = {
  sponsors: arrayOf(object).isRequired,
  value: arrayOf(number).isRequired,
  onChange: func.isRequired,
};

export default InputSponsors;
