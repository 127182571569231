import gql from 'graphql-tag';

// TODO: delete
export const CREATE_PARTNER = gql`
  mutation savePartner($input: PartnerSaveInput!) {
    savePartner(input: $input) {
      status
      description
    }
  }
`;
