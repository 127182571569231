import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import queryString from 'query-string';
import { Typography, Grid, Box } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { string, shape } from 'prop-types';

import { LOGIN } from 'apollo/mutations/auth-mutation';
import logoH from 'Images/logo/logo-h.svg';

const AuthCallback = ({ location, width }) => {
  const [login] = useMutation(LOGIN);
  const desktop = isWidthUp('sm', width);

  useEffect(() => {
    const loginUser = async () => {
      const parsedHash = queryString.parse(location.hash);
      await login({
        variables: {
          idToken: parsedHash.id_token,
        },
      });
      window.location.href = '/';
    };

    loginUser();
  }, []);

  return (
    <Box
      height="100%"
      px={desktop ? 0 : 3}
      component={Grid}
      container
      direction="column"
      justify="center"
      spacing={3}
    >
      <Grid item container justify="center">
        <Box width={300}>
          <img src={logoH} alt="logo" />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h2" align="center">
          Please wait!
        </Typography>
        <Typography variant="h6" align="center">
          You are being signed in to Admin Dashboard and will be directed to
          homepage soon.
        </Typography>
      </Grid>
    </Box>
  );
};

AuthCallback.propTypes = {
  location: shape({}).isRequired,
  width: string.isRequired,
};

export default withWidth()(AuthCallback);
