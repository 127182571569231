import gql from 'graphql-tag';

export const DEACTIVATE_PROJECT = gql`
  mutation deactivateProject($id: ID!) {
    deactivateProject(id: $id) {
      status
      message
    }
  }
`;

export const REACTIVATE_PROJECT = gql`
  mutation reactivateProject($id: ID!) {
    reactivateProject(id: $id) {
      status
      message
    }
  }
`;

export const FEATURE_PROJECT = gql`
  mutation featureProject($id: ID!) {
    featureProject(id: $id) {
      status
      message
    }
  }
`;

export const UNFEATURE_PROJECT = gql`
  mutation unfeatureProject($id: ID!) {
    unfeatureProject(id: $id) {
      status
      message
    }
  }
`;
