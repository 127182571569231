import { combineReducers } from 'redux';

import snackbarReducer from './snackbarReducer';
import backdropReducer from './backdropReducer';
import authReducer from './authReducer';
import dialogReducer from './dialogReducer';
import categoryReducer from './categoryReducer';
import projectReducer from './projectReducer';
import userReducer from './userReducer';
import announcementReducer from './announcementReducer';
import contestReducer from './contestReducer';
import partnerReducer from './partnerReducer';
import viewCountReducer from './viewCountReducer';
import toggleReducer from './toggleReducer';

export default combineReducers({
  snackbar: snackbarReducer,
  dialog: dialogReducer,
  backdrop: backdropReducer,
  auth: authReducer,
  category: categoryReducer,
  project: projectReducer,
  user: userReducer,
  partner: partnerReducer,
  announcement: announcementReducer,
  contest: contestReducer,
  viewCount: viewCountReducer,
  toggle: toggleReducer,
});
