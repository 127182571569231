import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { arrayOf, func, object } from 'prop-types';

import {
  Chip,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Box,
  FiberManualRecordIcon,
} from 'components/base';
import { makeStyles } from 'config/theme';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: theme.palette.common.white,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (id, cats, theme) => {
  return {
    fontWeight: _.find(cats, { id })
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
};

const InputCategories = ({ categories, value, onChange }) => {
  const classes = useStyles();

  const theme = useTheme();

  const [selectedCategories, setSelectedCategories] = useState(
    value.map(v => v.id),
  );

  const handleSetSelectedCategories = ({ target }) => {
    setSelectedCategories(target.value);
    onChange(target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="project-categories">Categories</InputLabel>
      <Select
        labelId="project-categories"
        id="project-categories-input"
        multiple
        value={selectedCategories}
        onChange={handleSetSelectedCategories}
        input={<Input id="select-multiple-project-categories" />}
        renderValue={selected => {
          return (
            <div className={classes.chips}>
              {selected.map(val => {
                // TODO FIX THIS PLEASE
                const cat = _.find(categories, { id: val });
                if (cat) {
                  return (
                    <Chip
                      size="small"
                      key={val}
                      label={cat.name || '-'}
                      className={classes.chip}
                      style={{ backgroundColor: cat.displayColor }}
                    />
                  );
                }
                return null;
              })}
            </div>
          );
        }}
        MenuProps={MenuProps}
      >
        {categories.map(({ id, name, displayColor }) => (
          <MenuItem
            key={id}
            value={id}
            style={getStyles(id, selectedCategories, theme)}
          >
            <Box mr={1}>
              <FiberManualRecordIcon style={{ color: displayColor }} />
            </Box>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

InputCategories.propTypes = {
  categories: arrayOf(object).isRequired,
  value: arrayOf(object).isRequired,
  onChange: func.isRequired,
};

export default InputCategories;
