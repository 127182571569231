import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';

import { getSnackbarState, closeSnackbar } from 'reducers/snackbarReducer';
import { Snackbar, MuiAlert } from 'components/base';
import { useUnload } from 'hooks';

const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

const CocoonSnackbar = ({ snackbar, handleCloseSnackbar }) => {
  // Prevent user reload when dialog is opened
  const message = snackbar.content.message
    ? snackbar.content.message
    : snackbar.content;
  useUnload(handleCloseSnackbar);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      onExited={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

CocoonSnackbar.propTypes = {
  snackbar: object.isRequired,
  handleCloseSnackbar: func.isRequired,
};

const mapStateToProps = state => ({
  snackbar: getSnackbarState(state),
});

const mapDispatchToProps = dispatch => ({
  handleCloseSnackbar: () => dispatch(closeSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CocoonSnackbar);
